import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const Sustainability = (props) => {

    let [claimTxt1, setClaimTxt1] = useState(0);
    let [claimTxt2, setClaimTxt2] = useState(0);
    let [claimTxt3, setClaimTxt3] = useState(0);

    let [claimAsset3, setClaimAsset3] = useState(0);

    let [claimImg2, setClaimImg2] = useState(0);
    let [claimImg3, setClaimImg3] = useState(0);

    let [claimDrop1, setClaimDrop1] = useState(-100);
    let [claimDrop2, setClaimDrop2] = useState(-100);
    let [claimDrop3, setClaimDrop3] = useState(-100);

    let [carbonSaved, setCarbonSaved] = useState(0);
    let [paperSaved, setPaperSaved] = useState(0);
    let [plasticSaved, setPlasticSaved] = useState(0);

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/sustainability/' + path.split('.')[0] + "." + ext);
    }

    const loadAnim = () => {
        if (document.documentElement.scrollTop == 0) {
            setTimeout(() => {
                setClaimDrop1(0)
                setClaimTxt1(1)
            }, 1000)
        }
    }
    const scores = () => {
        var now = new Date();
        //var start = new Date(now.getFullYear(), 0, 0);
        // var diff = now - start;
        // var oneDay = 1000 * 60 * 60 * 24;

        var seconds = now.getSeconds() + (60 * (now.getMinutes() + (60 * now.getHours())));
        // var day = Math.floor(diff / oneDay);
        // var hours = now.getHours()

        setCarbonSaved((((266880 / 366 / 24 / 60 / 60) * seconds).toFixed(2)).toString().replace(".", ","));
        setPaperSaved((((28500 / 366 / 24 / 60 / 60) * seconds).toFixed(3)).toString().replace(".", ","));
        setPlasticSaved((((16900 / 366 / 24 / 60 / 60) * seconds).toFixed(3)).toString().replace(".", ","));
    }
    const rainMachine = () => {

        const position = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
        const rainMachine = document.getElementsByClassName("sustainability");
        const drop = document.createElement("div");

        drop.classList.add("rain-drop");
        drop.style.left = position[Math.floor(Math.random() * (9 - 1 + 1)) + 1] + '%';
        rainMachine[0].appendChild(drop);

        setTimeout(() => {
            drop.classList.add("go");
        }, 500)
        setTimeout(() => {
            drop.remove();
        }, 26500)
    }

    const scrollCatch = () => {

        let scrolled = document.documentElement.scrollTop;
        let animation = parseInt(scrolled * 100 / (window.innerHeight * 2));

        let text1 = Math.abs(animation < 20 ? 1 : animation > 30 ? 0 : (animation - 20) / 10 + -1);
        let text2 = animation < 30 ? 0 : animation > 40 ? Math.abs(animation > 70 ? 0 : (animation - 60) / 10 + -1) : (animation - 30) / 10;
        let text3 = animation < 70 ? 0 : animation > 80 ? 1 : (animation - 70) / 10;

        let asset3 = animation < 70 ? 0 : animation > 80 ? 0.9 : (animation - 70) / 10;

        let image2 = animation < 20 ? 0 : animation > 40 ? 1 : (animation - 20) / 20;
        let image3 = animation < 60 ? 0 : animation > 80 ? 1 : (animation - 60) / 20;

        let drop1 = animation < 20 ? 0 : animation > 40 ? 100 : (animation - 20) * 5;
        let drop2 = animation < 20 ? -100 : animation > 40 ? animation > 60 ? animation < 80 ? (animation - 60) * 5 : 100 : 0 : (animation - 40) * 5;
        let drop3 = animation < 60 ? -100 : animation > 80 ? 0 : (animation - 60) * 5 - 100;

        setClaimTxt1(text1);
        setClaimTxt2(text2);
        setClaimTxt3(text3);

        setClaimAsset3(asset3);

        setClaimImg2(image2);
        setClaimImg3(image3);

        setClaimDrop1(drop1);
        setClaimDrop2(drop2);
        setClaimDrop3(drop3);
    }

    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader()
        window.addEventListener('scroll', scrollCatch);
        const startRain = setInterval(rainMachine, 3300);
        rainMachine();
        const startScores = setInterval(scores, 1000);
        scores();
        return () => {
            // This function will be called just before the component unmounts.
            // Remove listeners, subscriptions, etc.    
            clearInterval(startRain);
            clearInterval(startScores);
            window.removeEventListener('scroll', scrollCatch);
        };
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://fastio.pt/sustantabilidade" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>Sustentabilidade - Água do Fastio</title>
            </Helmet>
            <section className="sustainability">
                {/* <div className="header-helper"></div> */}
                <div className="claim-canvas">
                    <div className="claim">
                        <img className="background" src={setImage('backgrounds/claim-three-bg.png')} />
                        <img className="background" style={{ "opacity": claimImg2 }} src={setImage('backgrounds/claim-two-bg.png')} />
                        <img className="background" style={{ "opacity": claimImg3 }} src={setImage('backgrounds/claim-one-bg.png')} />
                        <div className="read-filter"></div>
                        <div className="container">
                            <div className="content">
                                <div className="text" style={{ "opacity": claimTxt1 }}>
                                    <h2>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str1}
                                    </h2>
                                </div>
                                <div className="text" style={{ "opacity": claimTxt2 }}>
                                    <h2>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str2}
                                    </h2>
                                    <p>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str3}
                                    </p>
                                </div>
                                <div className="text" style={{ "opacity": claimTxt3 }}>
                                    <h1>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str4}</h1>
                                    <p>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str5}
                                    </p>
                                    <p>
                                        {props.fastio.langKeys.sustainability.claim[props.fastio.lang].str6}
                                    </p>
                                    <p>
                                        {props.fastio.langKeys.sustainability.claim[props.fastio.lang].str7}
                                    </p>
                                </div>
                                <div className="drop" style={{ "top": claimDrop1 + 'vh' }} ><img src={setImage('drops/drop1.png')} /></div>
                                <div className="drop" style={{ "top": claimDrop2 + 'vh' }}><img src={setImage('drops/drop2.png')} /></div>
                                <div className="drop" style={{ "top": claimDrop3 + 'vh' }}><img src={setImage('drops/drop3.png')} /><div style={{ "opacity": claimAsset3 }} className="asset"><img src={setImage('backgrounds/infography.svg')} /></div></div>
                            </div>
                        </div>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            className="scroll-icon" viewBox="0 0 25.25 64.24" style={{ "enableBackground": "new 0 0 25.25 64.24" }} xmlSpace="preserve">
                            <style type="text/css">
                            </style>
                            <g>
                                <polygon style={{ "fill": "#FFFFFF" }} points="12.63,48.51 21.71,48.51 17.17,56.38 12.63,64.24 8.09,56.38 3.55,48.51 	" />
                                <g>
                                    <path style={{ "fill": "#FFFFFF" }} d="M12.63,41.59C5.66,41.59,0,35.92,0,28.96V12.63C0,5.66,5.66,0,12.63,0c6.96,0,12.63,5.66,12.63,12.63v16.33
                                    C25.25,35.92,19.59,41.59,12.63,41.59z M12.63,1C6.22,1,1,6.22,1,12.63v16.33c0,6.41,5.22,11.63,11.63,11.63
			s11.63-5.22,11.63-11.63V12.63C24.25,6.22,19.04,1,12.63,1z"/>
                                </g>
                                <path style={{ "fill": "#FFFFFF" }} d="M12.63,9.19c-1.13,0-2.05,0.92-2.05,2.05v2.77c0,1.13,0.92,2.05,2.05,2.05c1.13,0,2.05-0.92,2.05-2.05v-2.77
		C14.68,10.11,13.76,9.19,12.63,9.19z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="new-capsule">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.sustainability.capsule[props.fastio.lang].str1}
                                </h2>
                                <p>{props.fastio.langKeys.sustainability.capsule[props.fastio.lang].str2}
                                </p><p>
                                    {props.fastio.langKeys.sustainability.capsule[props.fastio.lang].str3}
                                </p><p>
                                    {props.fastio.langKeys.sustainability.capsule[props.fastio.lang].str4}
                                </p><p>
                                    {props.fastio.langKeys.sustainability.capsule[props.fastio.lang].str5}
                                </p>
                            </div>
                            <div className="stamp"><img src={setImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div>
                <div className="claim last">
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str8}</h2>
                                <p>{props.fastio.langKeys.sustainability.claim[props.fastio.lang].str9}
                                </p>
                                <p>
                                    {props.fastio.langKeys.sustainability.claim[props.fastio.lang].str10}
                                </p>
                                <p>
                                    {props.fastio.langKeys.sustainability.claim[props.fastio.lang].str11}</p>
                            </div>
                            <div className="drop"><img src={setImage('drops/drop4.png')} /></div>
                        </div>
                    </div>
                </div>
                <div className="recycling">
                    <div className="container">
                        <div className="content">
                            <h2>{props.fastio.langKeys.sustainability.recycling[props.fastio.lang].str1}</h2>
                            <ul>
                                <li>
                                    <img className="glass" src={setImage('recycling/glass.svg')} />
                                    <div className={"magnify glass-2 " + (isMobile && "mobile")}>
                                        <div className="recycle-color green"></div>
                                        <img src={setImage('recycling/glass-mag-2.png')} />
                                        <div className="hover-zone"></div>
                                    </div>
                                </li>
                                <li>
                                    <img className="bottle" src={setImage('recycling/bottle.svg')} />
                                    <div className={"magnify bottle-2 " + (isMobile && "mobile")}>
                                        <div className="recycle-color yellow"></div>
                                        <img src={setImage('recycling/bottle-mag-2.png')} />
                                        <div className="hover-zone"></div>
                                    </div>
                                </li>
                                <li>
                                    <img className="pack" src={setImage('recycling/pack.svg')} />
                                    <div className={"magnify pack " + (isMobile && "mobile")}>
                                        <div className="recycle-color blue"></div>
                                        <img src={setImage('recycling/pack-mag.png')} />
                                        <div className="hover-zone"></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="numbers">
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <ul>
                                <li>
                                    <span>{carbonSaved}</span>
                                    <div className="description">
                                        <p>{props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str1[0]}<br /> {props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str1[1]}</p>
                                        <br />
                                        <h3>{props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str2[0]} <br /> {props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str2[1]}
                                        </h3>
                                    </div>
                                </li>
                                <li>
                                    <span>{paperSaved}</span>
                                    <div className="description">
                                        <p>{props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str3[0]}<br /> {props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str3[1]}</p>
                                        <br />
                                        <h3>{props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str4[0]}<br /> {props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str4[1]}
                                        </h3>
                                    </div>
                                </li>
                                <li>
                                    <span>{plasticSaved}</span>
                                    <div className="description">
                                        <p>{props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str5[0]}<br /> {props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str5[1]}</p>
                                        <br />
                                        <h3>{props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str6[0]}<br /> {props.fastio.langKeys.sustainability.numbers[props.fastio.lang].str6[1]}
                                        </h3>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="quote">
                    <div className="container">
                        <div className="content">
                            <p>{props.fastio.langKeys.sustainability.quote[props.fastio.lang].str1}</p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

export default Sustainability;