
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const Header = (props) => {
    let elems = document.getElementsByClassName("animHeader");
    const loadAnim = () => {
        for (let key of elems) {
            key.style.height = "99%";
        }
    }
    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/header/' + path.split('.')[0] + "." + ext);
    }
    useEffect(() => {
    }, []);

    useEffect(() => {
        props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <header className={(props.fastio.preLoader ? "open" : "")}>
            <div className="container">
                <NavLink to="/home" className="logotipo" rel="noopener">
                    <img className="header-logo" src={setImage("logo.svg")} />
                </NavLink>
                <div className="image">
                    <div className={("water animHeader")}></div>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 197.62 108.48" style={{ "enableBackground": "new 0 0 197.62 108.48" }} xmlSpace="preserve">
                        <g>
                            <path style={{ "fill": "#FFFFFF" }} d="M0,0v108.48h197.62V0H0z M114.27,88.04c0,0-0.04,0.52-0.37,0.77c-0.32,0.26-0.31,0.71-0.05,0.89
		s0.36,0.49,0.36,0.91s0,1.9,0,1.9s0.07,0.42-0.33,0.83c-0.39,0.41-0.34,0.44-0.01,0.82c0.34,0.37,0.39,0.52,0.39,0.85
		s-0.02,2.92-0.19,4.26c-0.17,1.34-0.57,4.11-1.66,6.07c0,0-0.36,0.76-0.97,1.07c-0.61,0.31-2.74,0.95-4.12,1.19
		c-1.38,0.24-3.83,0.45-8.51,0.38c-4.68,0.07-7.18-0.14-8.56-0.38c-1.38-0.24-3.5-0.88-4.12-1.19c-0.61-0.31-0.97-1.07-0.97-1.07
		c-1.09-1.96-1.49-4.72-1.66-6.07s-0.19-3.93-0.19-4.26s0.05-0.48,0.39-0.85c0.34-0.37,0.39-0.41-0.01-0.82
		c-0.39-0.41-0.33-0.83-0.33-0.83s0-1.49,0-1.9s0.1-0.74,0.36-0.91c0.26-0.17,0.27-0.63-0.05-0.89c-0.32-0.26-0.37-0.77-0.37-0.77
		v-1.11c0-0.45,0.04-0.57,0.04-0.57l0.13-0.3l0.07-1.44c-0.05-0.02-0.11-0.16-0.11-0.16s0.24-27.94,0.24-28.27
		c0.01-0.33,0.04-0.37,0.04-0.37c-0.17-0.28-0.2-0.66-0.23-0.97c-0.03-0.31,0.01-0.61,0.15-1.02c0.14-0.41,0.8-2.33,0.96-2.9
		s0.75-0.81,0.75-0.81s-0.02-0.84-0.13-1.01c-0.11-0.17-0.08-0.42-0.08-0.42s0-0.69,0-1.8s0.05-1.3,0.24-1.55
		c0.2-0.25,0.41-0.74,0-1.04c-0.41-0.3-0.54-0.68-0.54-0.68c-0.3-0.64-0.9-2.54-0.9-2.54c-0.29-0.81-0.23-1.08-0.23-1.08
		s0-1.46,0-2.22s0.32-0.94,0.32-0.94c0.61-0.61,0.38-0.92,0.05-1.2c-0.33-0.27-0.37-0.8-0.37-0.8s0.04-1.73,0.04-2.04
		s0.01-0.87,0.33-1.09c0.32-0.22,0.49-0.6,0.04-1.05c-0.45-0.45-0.41-1.5-0.41-1.5v-1.59c0.04-2.19,0.94-3.94,1.59-5.11
		s1.82-2.62,1.82-2.62c0.48-1.42,1.22-3.39,2.35-5.65c1.13-2.26,3.96-6.22,4.18-6.56c0.23-0.34,0.23-0.69,0.23-0.69l0-1.05
		c-0.59-0.15-0.68-0.56-0.68-0.56l0-0.29c-0.18-0.16-0.11-0.44-0.11-0.44l0.09-0.55c0,0,0.02-0.25,0.03-0.4s0.05-0.38,0.11-0.53
		c0.05-0.15,0.06-0.24,0.06-0.24s0.02-0.59,0.08-1.3s0.6-0.8,0.6-0.8c1.2-0.31,4.73-0.25,4.73-0.25s3.52-0.06,4.73,0.25
		c0,0,0.53,0.09,0.6,0.8c0.07,0.71,0.08,1.3,0.08,1.3s0,0.09,0.06,0.24s0.1,0.38,0.11,0.53c0.01,0.15,0.03,0.4,0.03,0.4l0.09,0.55
		c0,0,0.06,0.27-0.11,0.44l0,0.29c0,0-0.09,0.41-0.68,0.56l0,1.05c0,0,0,0.35,0.23,0.69c0.23,0.34,3.06,4.3,4.18,6.56
		c1.13,2.26,1.86,4.23,2.35,5.65c0,0,1.17,1.45,1.82,2.62s1.55,2.93,1.59,5.11v1.59c0,0,0.05,1.05-0.41,1.5
		c-0.45,0.45-0.28,0.83,0.04,1.05s0.33,0.78,0.33,1.09s0.04,2.04,0.04,2.04s-0.03,0.53-0.37,0.8c-0.33,0.27-0.56,0.59,0.05,1.2
		c0,0,0.32,0.17,0.32,0.94s0,2.22,0,2.22s0.06,0.27-0.23,1.08c0,0-0.6,1.9-0.9,2.54c0,0-0.14,0.38-0.54,0.68s-0.2,0.79,0,1.04
		s0.24,0.44,0.24,1.55s0,1.8,0,1.8s0.03,0.25-0.08,0.42c-0.11,0.17-0.13,1.01-0.13,1.01s0.59,0.24,0.75,0.81
		c0.16,0.57,0.82,2.49,0.96,2.9c0.14,0.41,0.18,0.71,0.15,1.02s-0.05,0.69-0.23,0.97c0,0,0.03,0.04,0.04,0.37
		c0.01,0.33,0.24,28.27,0.24,28.27s-0.06,0.14-0.11,0.16l0.07,1.44l0.13,0.3c0,0,0.04,0.12,0.04,0.57V88.04z"/>
                        </g>
                        <g>
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="4.93,76.99 19.52,45.35 30.32,69.98 	" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="27.31,63.12 31.83,54.25 42.44,76.99 	" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="42.44,76.99 57.03,45.35 67.83,69.98 	" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="64.83,63.12 69.35,54.25 79.96,76.99 	" />
                            <line style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} x1="79.96" y1="76.99" x2="83.61" y2="69.08" />
                            <line style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} x1="114.02" y1="69.59" x2="117.47" y2="76.99" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="117.47,76.99 132.06,45.35 142.86,69.98 	" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="139.86,63.12 144.38,54.25 154.99,76.99 	" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="154.99,76.99 169.58,45.35 180.38,69.98 	" />
                            <polyline style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} points="177.37,63.12 181.89,54.25 192.51,76.99 	" />
                            <line style={{ "fill": "none", "stroke": "#00AEEF", "strokeWidth": "2", "strokeMiterlimit": "10" }} x1="114.02" y1="76.99" x2="197.62" y2="76.99" />
                            <line style={{ "fill": "none", "stroke": "#00AEEF", "strokeWidth": "2", "strokeMiterlimit": "10" }} x1="0" y1="76.99" x2="83.61" y2="76.99" />
                        </g>
                        <path style={{ "fill": "none", "stroke": "#00AEEF", "strokeMiterlimit": "10" }} d="M113.91,88.82c0.32-0.26,0.37-0.77,0.37-0.77v-1.11c0-0.45-0.04-0.57-0.04-0.57l-0.13-0.3l-0.07-1.44
	c0.05-0.02,0.11-0.16,0.11-0.16s-0.24-27.94-0.24-28.27s-0.04-0.37-0.04-0.37c0.17-0.28,0.2-0.66,0.23-0.97
	c0.03-0.31-0.01-0.61-0.15-1.02c-0.14-0.41-0.8-2.33-0.96-2.9c-0.16-0.57-0.75-0.81-0.75-0.81s0.02-0.84,0.13-1.01
	c0.11-0.17,0.08-0.42,0.08-0.42s0-0.69,0-1.8c0-1.11-0.05-1.3-0.24-1.55c-0.2-0.25-0.41-0.74,0-1.04c0.41-0.3,0.54-0.68,0.54-0.68
	c0.3-0.64,0.9-2.54,0.9-2.54c0.29-0.81,0.23-1.08,0.23-1.08s0-1.46,0-2.22c0-0.76-0.32-0.94-0.32-0.94
	c-0.61-0.61-0.38-0.92-0.05-1.2c0.33-0.27,0.37-0.8,0.37-0.8s-0.04-1.73-0.04-2.04c0-0.31-0.01-0.87-0.33-1.09
	c-0.32-0.22-0.49-0.6-0.04-1.05c0.45-0.45,0.41-1.5,0.41-1.5v-1.59c-0.04-2.19-0.94-3.94-1.59-5.11c-0.65-1.17-1.82-2.62-1.82-2.62
	c-0.48-1.42-1.22-3.39-2.35-5.65c-1.13-2.26-3.96-6.22-4.18-6.56c-0.23-0.34-0.23-0.69-0.23-0.69l0-1.05
	c0.59-0.15,0.68-0.56,0.68-0.56l0-0.29c0.18-0.16,0.11-0.44,0.11-0.44l-0.09-0.55c0,0-0.02-0.25-0.03-0.4
	c-0.01-0.15-0.05-0.38-0.11-0.53c-0.05-0.15-0.06-0.24-0.06-0.24s-0.02-0.59-0.08-1.3c-0.07-0.71-0.6-0.8-0.6-0.8
	c-1.2-0.31-4.73-0.25-4.73-0.25s-3.52-0.06-4.73,0.25c0,0-0.53,0.09-0.6,0.8c-0.07,0.71-0.08,1.3-0.08,1.3s0,0.09-0.06,0.24
	c-0.05,0.15-0.1,0.38-0.11,0.53c-0.01,0.15-0.03,0.4-0.03,0.4L93.12,4.6c0,0-0.06,0.27,0.11,0.44l0,0.29c0,0,0.09,0.41,0.68,0.56
	l0,1.05c0,0,0,0.35-0.23,0.69c-0.23,0.34-3.06,4.3-4.18,6.56c-1.13,2.26-1.86,4.23-2.35,5.65c0,0-1.17,1.45-1.82,2.62
	c-0.65,1.17-1.55,2.93-1.59,5.11v1.59c0,0-0.05,1.05,0.41,1.5c0.45,0.45,0.28,0.83-0.04,1.05c-0.32,0.22-0.33,0.78-0.33,1.09
	c0,0.31-0.04,2.04-0.04,2.04s0.03,0.53,0.37,0.8c0.33,0.27,0.56,0.59-0.05,1.2c0,0-0.32,0.17-0.32,0.94c0,0.76,0,2.22,0,2.22
	s-0.06,0.27,0.23,1.08c0,0,0.6,1.9,0.9,2.54c0,0,0.14,0.38,0.54,0.68c0.41,0.3,0.2,0.79,0,1.04c-0.2,0.25-0.24,0.44-0.24,1.55
	c0,1.11,0,1.8,0,1.8s-0.03,0.25,0.08,0.42c0.11,0.17,0.13,1.01,0.13,1.01s-0.59,0.24-0.75,0.81c-0.16,0.57-0.82,2.49-0.96,2.9
	c-0.14,0.41-0.18,0.71-0.15,1.02c0.03,0.31,0.05,0.69,0.23,0.97c0,0-0.03,0.04-0.04,0.37c-0.01,0.33-0.24,28.27-0.24,28.27
	s0.06,0.14,0.11,0.16l-0.07,1.44l-0.13,0.3c0,0-0.04,0.12-0.04,0.57v1.11c0,0,0.04,0.52,0.37,0.77c0.32,0.26,0.31,0.71,0.05,0.89
	c-0.26,0.17-0.36,0.49-0.36,0.91c0,0.42,0,1.9,0,1.9s-0.07,0.42,0.33,0.83c0.39,0.41,0.34,0.44,0.01,0.82
	c-0.34,0.37-0.39,0.52-0.39,0.85c0,0.33,0.02,2.92,0.19,4.26c0.17,1.34,0.57,4.11,1.66,6.07c0,0,0.36,0.76,0.97,1.07
	c0.61,0.31,2.74,0.95,4.12,1.19c1.38,0.24,3.88,0.45,8.56,0.38c4.68,0.07,7.13-0.14,8.51-0.38c1.38-0.24,3.5-0.88,4.12-1.19
	c0.61-0.31,0.97-1.07,0.97-1.07c1.09-1.96,1.49-4.72,1.66-6.07c0.17-1.34,0.19-3.93,0.19-4.26c0-0.33-0.05-0.48-0.39-0.85
	c-0.34-0.37-0.39-0.41,0.01-0.82c0.39-0.41,0.33-0.83,0.33-0.83s0-1.49,0-1.9c0-0.42-0.1-0.74-0.36-0.91
	C113.6,89.53,113.58,89.08,113.91,88.82z"/>
                    </svg>
                </div>
                <div className="text-container">
                    <div className="parallelepiped">
                        <div className="front">A carregar</div>
                        <div className="top">Loading</div>
                        <div className="back">Cargando</div>
                        <div className="bottom">Chargement</div>
                    </div>
                </div>
            </div>
        </header>
    );

}

export default Header;