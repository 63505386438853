
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const Nav = (props) => {
	let [isOpen, setIsOpen] = useState(false);
	let [isHoverNav, setIsHoverNav] = useState(true);
	let [audioState, setAudioState] = useState(false);
	let [autoPlay, setAutoPlay] = useState(true);
	// let elems = document.getElementsByClassName("animHeader");

	const loadAnim = () => {
		// for (let key of elems) {
		// 	// key.style.height = "99%";
		// }
	}
	const setImage = (path) => {
		var ext = path.split('.')[1];
		if (isWebpSupported() && isMobile) {
			// if (isWebpSupported()) {
			switch (ext) {
				case "jpg":
				case "gif":
				case "png":
					ext = "webp";
					break;
			}
		}
		return require('../../img/header/' + path.split('.')[0] + "." + ext);
	}
	const setAudio = (path) => {
		var ext = path.split('.')[1];
		return require('../../audio/' + path.split('.')[0] + "." + ext);
	}
	const enquire = () => {
		if (window.matchMedia('(min-width: 1120px)').matches) {
			if (isOpen === true) { setIsOpen(false); setIsHoverNav(true) }
		}
	}
	const audioPlay = () => {
		const audio = document.getElementById("fastioAudio");
		audio.volume = 0.3;
		if (audioState) { audio.pause(); setAudioState(false); } else { audio.play(); setAudioState(true); }

	}
	const scrollIcon = () => {
		if (document.getElementsByClassName("scroll-icon").length > 0) {
			document.documentElement.scrollTop == 0 ? document.getElementsByClassName("scroll-icon")[0].classList.add("active") : document.getElementsByClassName("scroll-icon")[0].classList.remove("active");
		}
	}
	const scrollCatchMenu = () => {
		scrollIcon();
		if (isOpen === true && isHoverNav == false) { setIsOpen(false) }

	}
	useEffect(() => {
		scrollIcon();
		window.addEventListener('scroll', scrollCatchMenu);
		window.addEventListener("resize", enquire);
	}, [isOpen, isHoverNav]);
	useEffect(() => {
		props.fastio.preLoader && loadAnim();
	}, [props, isOpen, isHoverNav]);
	return (

		<nav className={isOpen ? "open" : ""} onMouseOver={() => setIsHoverNav(true)} onMouseOut={() => setIsHoverNav(false)}>
			<audio id="fastioAudio" loop>
				<source src={setAudio('sound.ogg')} type="audio/ogg" />
				<source src={setAudio('sound.mp3')} type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
			<div className="container">
				<div className="content">
					<div className="mobile-btn" onClick={() => isOpen ? setIsOpen(false) : setIsOpen(true)}>
						<div className="bar"></div>
						<div className="bar"></div>
						<div className="bar"></div>
					</div>
					<ul>
						<li className={"sound-control " + audioState}>
							<a onClick={() => audioPlay()}>
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 38.6 31.7" style={{ "enableBackground": "new 0 0 38.6 31.7" }} xmlSpace="preserve">
									<g>
										<path className="st0" d="M0,9.2v6.6v6.6h7l9.9,9.2V15.9V0L7,9.2H0z M15.9,2.3v13.6v13.6l-8.5-7.9H1v-5.6v-5.6h6.4L15.9,2.3z" />
										<path className="st0" d="M24.1,15.9c0-2.7-2.2-4.1-2.3-4.2l-0.6,1c0.1,0,2,1.3,2,3.4s-1.8,3.3-1.8,3.3l0.5,0.9c0.1-0.1,2.3-1.5,2.3-4.2
		C24.1,16,24.1,15.9,24.1,15.9L24.1,15.9z"/>
										<path className="st0" d="M31.2,14.7c-0.6-6.3-5.8-9.1-6.1-9.3l-0.6,1.1c0.1,0,5,2.7,5.6,8.3c0,0.3,0.1,0.7,0.1,1h0c0,0.1,0,0.2,0,0.2
		c0,6.5-5.6,9.5-5.6,9.5l0.5,0.9c0.3-0.1,6.1-3.3,6.1-10.4C31.2,15.6,31.2,15.2,31.2,14.7z"/>
										<path className="st0" d="M38.5,14.1c-0.9-9.4-8.4-13.8-8.5-13.8l-0.6,1c0.1,0,7.1,4.2,8,12.9c0.1,0.5,0.1,1.1,0.1,1.7h0
		c0,0.1,0,0.2,0,0.2c0,10-8,14.7-8,14.8l0.5,0.9c0.1,0,8.5-5,8.5-15.6C38.6,15.4,38.6,14.7,38.5,14.1z"/>
									</g>
								</svg>
							</a>
						</li>
						<li className="logotipo">
							<NavLink to="/home" rel="noopener">
								<img className="header-logo" src={setImage("logo_small.svg")} />
							</NavLink>
						</li>
						<li className="languages">
							<div className="selector">
								<div className="selected"><a>{props.fastio.lang}</a></div>
								<span className="list">
									{props.fastio.langs.filter(lang => !lang.includes(props.fastio.lang)).map((lang, i) => (
										<a key={i} onClick={() => props.fastio.switchLang(lang)}>{lang}</a>
									))}
								</span>
							</div>
						</li>
						<li>
							<NavLink to="/home" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{ "enableBackground": "new 0 0 48.7 54.4" }} xmlSpace="preserve">
									<path d="M43.38,42.13L32.71,19.25L28.2,28.1l-7.82-17.83L5.69,42.13H0.86v2h46.99v-2H43.38z M20.36,12.71l10.35,23.61
	l0.92-0.4l-2.91-6.64l3.95-7.75l9.61,20.6H6.79L20.36,12.71z"/>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str1}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/historia" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{ "enableBackground": "new 0 0 48.7 54.4" }} xmlSpace="preserve">
									<g>
										<polygon points="44.87,39.2 44.87,13.25 31.86,20.2 31.86,13.2 19.73,20.53 19.73,13.23 6.1,21 6.1,25.84 7.1,25.84 
		7.1,21.58 18.73,14.95 18.73,22.3 30.86,14.97 30.86,21.87 43.87,14.92 43.87,39.2 7.1,39.2 7.1,35.56 6.1,35.56 6.1,39.2 
		1.71,39.2 1.71,41.2 48.7,41.2 48.7,39.2 	"/>
										<path d="M32.23,27.28v6.94h6.94v-6.94H32.23z M38.16,33.22h-4.94v-4.94h4.94V33.22z" />
										<path d="M21.7,27.28v6.94h6.94v-6.94H21.7z M27.64,33.22H22.7v-4.94h4.94V33.22z" />
										<path d="M0,30.75l6.6,3.81v-3.81v-3.81L0,30.75z M5.6,32.83L2,30.75l3.6-2.08V32.83z" />
										<path d="M13.2,26.94l-6.6,3.81l6.6,3.81V26.94z M12.2,32.83l-3.6-2.08l3.6-2.08V32.83z" />
									</g>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str2}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/sustentabilidade" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{
										"enableBackground": "new 0 0 48.7 54.4"
									}} xmlSpace="preserve" >
									<path d="M46.18-634.04L35.5-656.92L31-648.08l-7.82-17.83L8.49-634.04H3.65v2h46.99v-2H46.18z M23.16-663.47
	l10.35,23.61l0.92-0.4l-2.91-6.64l3.95-7.75l9.61,20.6H9.59L23.16-663.47z"/>
									<path d="M37.22-600.45c0-7.18-4.55-13.31-10.91-15.7c-0.04-0.04-0.07-0.07-0.08-0.09l-0.04,0.04
	c-1.8-0.66-3.73-1.04-5.75-1.04c-9.25,0-16.78,7.53-16.78,16.78s7.53,16.79,16.78,16.79c2.02,0,3.96-0.38,5.76-1.04l0.04,0.04
	c0.01-0.01,0.04-0.04,0.08-0.09C32.67-587.14,37.22-593.27,37.22-600.45z M4.68-599.95H9.6c0.05,2.95,0.49,5.46,1.1,7.54H6.87
	C5.55-594.64,4.76-597.2,4.68-599.95z M36.2-600.95h-4.7c-0.05-2.95-0.49-5.46-1.1-7.54H34C35.32-606.27,36.11-603.7,36.2-600.95z
	 M30.5-600.95h-9.56v-7.54h8.4C29.98-606.43,30.45-603.92,30.5-600.95z M20.94-609.49v-6.72c1.68,0.05,3.29,0.37,4.8,0.91
	c0.54,0.61,2.07,2.54,3.26,5.81H20.94z M19.94-609.49H12.1c1.29-3.54,2.98-5.51,3.37-5.94c1.41-0.47,2.91-0.74,4.46-0.78V-609.49z
	 M19.94-608.49v7.54h-9.33c0.05-2.97,0.52-5.48,1.16-7.54H19.94z M9.6-600.95H4.68c0.09-2.75,0.88-5.31,2.2-7.54h3.83
	C10.09-606.41,9.65-603.9,9.6-600.95z M10.61-599.95h9.33v7.54h-8.17C11.12-594.47,10.65-596.98,10.61-599.95z M19.94-591.41v6.72
	c-1.55-0.05-3.05-0.32-4.46-0.78c-0.39-0.43-2.09-2.39-3.38-5.94H19.94z M20.94-591.41H29c-1.19,3.27-2.72,5.2-3.26,5.81
	c-1.51,0.54-3.12,0.86-4.8,0.91V-591.41z M20.94-592.41v-7.54h9.56c-0.05,2.97-0.52,5.48-1.16,7.54H20.94z M31.5-599.95h4.7
	c-0.09,2.75-0.88,5.31-2.2,7.54h-3.6C31.01-594.49,31.45-597,31.5-599.95z M33.36-609.49h-3.28c-0.76-2.22-1.69-3.88-2.45-5
	C29.92-613.3,31.89-611.58,33.36-609.49z M13.58-614.65c-0.78,1.11-1.76,2.83-2.56,5.16H7.51C9.06-611.69,11.15-613.47,13.58-614.65
	z M7.51-591.41h3.51c0.8,2.33,1.78,4.05,2.56,5.16C11.15-587.43,9.06-589.21,7.51-591.41z M27.63-586.42c0.76-1.12,1.69-2.78,2.45-5
	h3.29C31.89-589.32,29.92-587.6,27.63-586.42z"/>
									<path d="M44.56-520.73L19.8-500.08l0.02,0.01l-2.3,5.2l-1.05-7.36l27.27-18.94L4.14-506.9l11.34,4.76l1.43,10.01
	l7.33-5.97l10.22,4.58L44.56-520.73z M36.92-517.65l-21.02,14.61l-9-3.78L36.92-517.65z M18.58-494.77l2.16-4.89l2.48,1.11
	L18.58-494.77z M21.67-500.34l20.64-17.22l-8.43,22.7L21.67-500.34z"/>
									<g>
										<g>
											<path d="M21.66-695.97v9.26l8.02-4.63L21.66-695.97z M22.66-694.24l5.02,2.9l-5.02,2.9V-694.24z M24.52-683.34
			c4.41,0,8-3.59,8-8s-3.59-8-8-8s-8,3.59-8,8S20.11-683.34,24.52-683.34z M24.52-698.35c3.86,0,7,3.14,7,7c0,3.86-3.14,7-7,7
			s-7-3.14-7-7C17.52-695.21,20.66-698.35,24.52-698.35z M3.65-703.91v25.13h43v-25.13H3.65z M45.65-679.78h-41v-23.13h41V-679.78z"
											/>
										</g>
									</g>
									<g>
										<path d="M30.62-244.98L18.01-245c-0.06-0.02-1.53-0.46-1.53-2.53v-18.71c-0.11-0.41-0.29-1.85,0.5-6.03
		c0.8-4.26,3.54-19.63,4.11-22.84h-0.8v-1.69h0.54l0.13-0.21l0.33-2.37h6.14l0.33,2.37l0.17,0.21h0.5v1.69h-0.8
		c0.57,3.21,3.31,18.58,4.11,22.84l0,0c0.78,4.18,0.6,5.62,0.5,6.03v18.71c0,2.07-1.47,2.52-1.53,2.53L30.62-244.98z M18.23-245.98
		h12.23c0.18-0.08,0.76-0.42,0.76-1.55l0.03-18.96c0.01-0.03,0.32-1.14-0.51-5.6l0,0c-0.89-4.75-4.19-23.25-4.22-23.44l-0.1-0.59
		h0.71l-0.37-0.66l-0.23-1.61h-4.4l-0.3,1.81l-0.29,0.46h0.71l-0.1,0.59c-0.03,0.19-3.33,18.69-4.22,23.44
		c-0.84,4.45-0.52,5.57-0.5,5.61l0.03,0.16v18.78C17.47-246.4,18.06-246.06,18.23-245.98z"/>
									</g>
									<g>
										<g>
											<path d="M5.59-383.76v2h37.52v-2H5.59z M5.59-371.02h37.52v-2H5.59V-371.02z M5.59-360.28h37.52v-2H5.59V-360.28z" />
										</g>
									</g>
									<g>
										<g>
											<path d="M24.35-470.29c-9.25,0-16.78,7.53-16.78,16.78s7.53,16.79,16.78,16.79s16.78-7.53,16.78-16.79
			S33.6-470.29,24.35-470.29z M25.1-437.76v-2.79h-1.5v2.79c-8.1-0.38-14.61-6.89-15-15h2.79v-1.5H8.6c0.38-8.1,6.89-14.61,15-15
			v2.69h1.5v-2.69c8.1,0.38,14.61,6.89,15,15h-2.69v1.5h2.69C39.71-444.65,33.2-438.14,25.1-437.76z M22.38-478.52l-0.19-1.56
			l0.04-0.01l0.59,1.26l3.11,5.47h1.57v-8.44H26.1v5.16l0.18,1.56l-0.04,0.01l-0.58-1.26l-3.08-5.47H21v8.44h1.38V-478.52z
			 M23.6-453.5h1.5v-10.5h-1.5V-453.5z"/>
										</g>
									</g>
									<g>
										<polygon points="44.87,-400.4 44.87,-426.35 31.86,-419.4 31.86,-426.4 19.73,-419.07 19.73,-426.37 6.1,-418.6 
		6.1,-413.77 7.1,-413.77 7.1,-418.02 18.73,-424.65 18.73,-417.3 30.86,-424.63 30.86,-417.73 43.87,-424.68 43.87,-400.4 
		7.1,-400.4 7.1,-404.04 6.1,-404.04 6.1,-400.4 1.71,-400.4 1.71,-398.4 48.7,-398.4 48.7,-400.4 	"/>
										<path d="M32.23-412.32v6.94h6.94v-6.94H32.23z M38.16-406.39h-4.94v-4.94h4.94V-406.39z" />
										<path d="M21.7-412.32v6.94h6.94v-6.94H21.7z M27.64-406.39H22.7v-4.94h4.94V-406.39z" />
										<path d="M0-408.86l6.6,3.81v-3.81v-3.81L0-408.86z M5.6-406.78L2-408.86l3.6-2.08V-406.78z" />
										<path d="M13.2-412.67l-6.6,3.81l6.6,3.81V-412.67z M12.2-406.78l-3.6-2.08l3.6-2.08V-406.78z" />
									</g>
									<g>
										<g>
											<path d="M41.3-547.48c-1.13-0.5-2.25-0.45-3.25,0.13c-0.68,0.4-1.18,1.04-1.58,2.01c-0.93,2.29-1.17,4.69-1.28,6.6
			l0.02,0.32c0.02,0.28,0.03,0.56,0.07,0.84c0.09,0.64,0.31,1.53,1.18,1.92c0.51,0.23,1.02,0.34,1.53,0.34
			c0.59,0,1.16-0.15,1.71-0.46c0.94-0.52,1.34-1.49,1.64-2.35c0.51-1.44,0.86-3.07,1.06-4.98c0.08-0.72,0.1-1.5,0.06-2.46
			C42.44-546.48,42.01-547.17,41.3-547.48z M41.2-543.25L41.2-543.25c-0.2,1.82-0.52,3.36-1,4.71c-0.25,0.71-0.52,1.38-1.08,1.69
			c-0.68,0.38-1.41,0.4-2.15,0.07c-0.18-0.08-0.37-0.26-0.48-0.98c-0.03-0.24-0.05-0.49-0.06-0.73l-0.02-0.26
			c0.1-1.74,0.33-4.01,1.19-6.13c0.29-0.71,0.63-1.16,1.06-1.42c0.35-0.2,0.71-0.31,1.09-0.31c0.33,0,0.68,0.08,1.05,0.24
			c0.37,0.16,0.44,0.6,0.45,0.85C41.29-544.63,41.28-543.91,41.2-543.25z M47.18-546.83c-0.28-2.27-1.53-3.85-3.53-4.43
			c-1.81-0.53-3.75-0.6-5.75-0.23c-0.75,0.14-1.45,0.4-2.09,0.75h-5.98c-0.79,0-1.44,0.68-1.44,1.52s0.64,1.52,1.44,1.52h1.88
			l0.19,0.02c0.07,0.01,0.16,0.11,0.16,0.25l0,0.04c0,0.03-0.01,0.07-0.03,0.1c-0.03,0.07-0.09,0.12-0.14,0.13l-1.49,0.02
			c-0.64,0-1.19,0.43-1.41,1.03c-0.22-0.6-0.77-1.03-1.41-1.03h-0.46c-0.4,0-0.76,0.17-1.03,0.44c0.08-0.1,0.16-0.2,0.22-0.32
			c0.44-0.94,0.69-1.82,0.77-2.67c0.07-0.67-0.23-1.2-0.8-1.47c-0.31-0.14-0.63-0.25-0.94-0.31c-2-0.41-4.01-0.44-5.97-0.07
			c-1.23,0.23-2.33,0.74-3.28,1.51h-4.4c-0.76,0-1.38,0.66-1.38,1.46c0,0.81,0.62,1.46,1.38,1.46h1.76l0.17,0.02
			c0.04,0,0.11,0.08,0.11,0.18l0,0.05c0,0.03-0.01,0.05-0.02,0.08l-0.11,0.1l-0.02,0c-0.25,0.01-1.37,0.01-1.37,0.01
			c-0.59,0-1.09,0.37-1.32,0.9c-0.08-0.18-0.18-0.35-0.32-0.48c-0.27-0.27-0.63-0.42-1-0.42c0,0,0,0,0,0H9.13
			c-0.8,0-1.45,0.69-1.45,1.54c0,0.85,0.65,1.54,1.45,1.54h0.43c0.58,0,1.09-0.38,1.32-0.91c0.23,0.52,0.73,0.89,1.33,0.89
			l2.47-0.01c0.05,0.02,0.11,0.1,0.11,0.21c0,0.11-0.06,0.22-0.15,0.22l-2.86,0.02c-0.81,0-1.47,0.71-1.47,1.58
			c0,0.87,0.66,1.58,1.47,1.58l1.05,0.01c0.03,0.02,0.08,0.08,0.08,0.19c0,0.11-0.07,0.19-0.11,0.2H9.47
			c-0.62,0-1.12,0.41-1.31,0.99c-0.06-0.2-0.16-0.39-0.3-0.55c-0.26-0.29-0.62-0.45-1-0.45H6.7c-0.76,0-1.39,0.66-1.39,1.46
			c0,0.38,0.14,0.74,0.39,1.02c0.27,0.29,0.62,0.44,1,0.44h0.16c0.61,0,1.12-0.41,1.31-0.99c0.19,0.57,0.7,0.98,1.3,0.98l3.96,0
			c0.04,0.01,0.13,0.09,0.13,0.22c0,0.13-0.08,0.21-0.14,0.22l-0.59,0.01c-0.3,0.03-0.57,0.16-0.79,0.38
			c-0.3,0.28-0.46,0.68-0.46,1.1c0,0.81,0.6,1.47,1.4,1.5h0.6c0.24,0.25,0.5,0.48,0.8,0.69c0.92,0.64,1.99,0.99,3.27,1.06
			c0.33,0.02,0.67,0.03,1.02,0.03c0.92,0,1.86-0.06,2.82-0.19c1.14-0.15,2-0.37,2.79-0.7c0.69-0.29,1.1-0.83,1.26-1.64
			c0.21-1.11,0.44-2.21,0.66-3.32c0.24,0.48,0.71,0.81,1.26,0.81l4.24,0c0.07,0.01,0.17,0.12,0.17,0.27c0,0.15-0.1,0.27-0.19,0.27
			l-0.55,0c-0.33,0.02-0.65,0.16-0.9,0.4c-0.31,0.3-0.48,0.71-0.48,1.14c0,0.84,0.63,1.53,1.45,1.56h1.26
			c0.43,0.36,0.94,0.66,1.52,0.87c1.2,0.45,2.43,0.52,3.87,0.56l0.36-0.03c0.41-0.03,0.94-0.08,1.46-0.16
			c1.18-0.18,2.26-0.63,3.19-1.34c1.12-0.84,2-2.05,2.68-3.69c0.72-1.72,1.21-3.66,1.5-5.91C47.23-543.94,47.36-545.36,47.18-546.83
			z M9.57-544.83H9.13c-0.1,0-0.23-0.14-0.23-0.32c0-0.17,0.11-0.32,0.23-0.32h0.43h0c0.06,0,0.11,0.03,0.13,0.06
			c0.06,0.06,0.1,0.16,0.1,0.26C9.8-544.97,9.67-544.83,9.57-544.83z M6.86-537.93H6.7c-0.04,0-0.08-0.03-0.09-0.04
			c-0.05-0.05-0.07-0.12-0.07-0.2c0-0.14,0.09-0.24,0.16-0.24h0.16c0.04,0,0.08,0.03,0.09,0.05c0.05,0.05,0.07,0.12,0.07,0.19
			C7.03-538.04,6.94-537.93,6.86-537.93z M27.11-545.93h0.46c0.16,0,0.29,0.17,0.29,0.38c0,0.2-0.14,0.38-0.29,0.38h-0.46
			c-0.15,0-0.29-0.18-0.29-0.38C26.82-545.75,26.95-545.93,27.11-545.93z M25.88-542.05L25.88-542.05c-0.15,0.8-0.31,1.6-0.47,2.4
			l-0.35,1.71c-0.25,1.21-0.49,2.42-0.73,3.64c-0.09,0.47-0.28,0.64-0.53,0.75c-0.69,0.29-1.46,0.48-2.48,0.62
			c-1.25,0.17-2.46,0.22-3.61,0.16c-1.05-0.06-1.91-0.33-2.64-0.84c-0.29-0.2-0.55-0.44-0.76-0.69l-0.18-0.22h-0.29l-0.85,0
			c-0.11,0-0.2-0.13-0.2-0.28c0-0.08,0.03-0.16,0.14-0.26l0.59,0c0.7-0.06,1.25-0.69,1.25-1.44c0-0.71-0.5-1.33-1.13-1.42
			c-0.08-0.02-0.15-0.02-0.24-0.02H9.47c-0.09,0-0.16-0.11-0.16-0.23c0-0.13,0.07-0.24,0.16-0.24h3.34c0.1,0,0.18-0.02,0.21-0.02
			c0.64-0.11,1.13-0.71,1.13-1.4c0-0.55-0.3-1.05-0.75-1.28c-0.09-0.05-0.19-0.09-0.3-0.11c-0.1-0.02-0.2-0.03-0.3-0.03h-1.01
			c-0.14,0-0.25-0.16-0.25-0.36c0-0.19,0.11-0.36,0.25-0.36l2.86-0.02c0.77,0,1.37-0.63,1.37-1.45c0-0.63-0.38-1.18-0.95-1.37
			c-0.14-0.05-0.28-0.07-0.42-0.07l-2.44,0.02c-0.12,0-0.23-0.14-0.23-0.3c0-0.17,0.11-0.32,0.23-0.32c0.88,0,1.31,0,1.55-0.03l0,0
			c0.22-0.03,0.42-0.11,0.58-0.22c0.21-0.14,0.38-0.35,0.49-0.59c0.09-0.19,0.13-0.4,0.13-0.62l-0.01-0.11
			c-0.05-0.6-0.46-1.11-1-1.26l-0.07-0.06h-2.2c-0.07,0-0.16-0.09-0.16-0.24c0-0.15,0.09-0.24,0.16-0.24h3.9l-0.09,0.92l1.16-1.03
			c0.85-0.75,1.8-1.21,2.93-1.43c1.8-0.33,3.65-0.31,5.5,0.07c0.22,0.04,0.45,0.12,0.68,0.23c0.08,0.04,0.11,0.05,0.1,0.23
			c-0.07,0.72-0.29,1.47-0.67,2.28c-0.02,0.05-0.03,0.06-0.1,0.05c-0.33-0.04-0.66-0.1-0.99-0.15c-0.25-0.04-0.5-0.08-0.75-0.12
			c-0.96-0.15-1.82-0.14-2.63,0.01c-1.06,0.21-1.94,0.85-2.6,1.93c-0.78,1.26-1.23,2.75-1.43,4.67c-0.12,1.17-0.1,2.11,0.06,2.97
			c0.14,0.78,0.45,1.87,1.54,2.4c1,0.49,2.09,0.52,3.34,0.07c0.4-0.15,0.67-0.5,0.76-0.99c0.08-0.39,0.15-0.78,0.23-1.18l0.25-1.3
			l-1.87,0c-0.2,0-0.39,0-0.62-0.01c0,0,0,0,0,0c0.05-0.34,0.12-0.68,0.19-1.02l0.32-1.57c0.04-0.19,0.07-0.26,0.07-0.26
			c0.02-0.01,0.07-0.02,0.19-0.02l3.09,0l1.55,0c0.03,0,0.07,0,0.13,0.01C25.88-542.06,25.88-542.05,25.88-542.05z M26.89-542.82
			c-0.13-0.18-0.39-0.41-0.85-0.46c-0.1-0.01-0.2-0.02-0.29-0.02l-1.54,0l-3.09,0c-1.07,0-1.34,0.68-1.46,1.27l-0.32,1.57
			c-0.07,0.36-0.14,0.71-0.19,1.07c-0.06,0.42,0.01,0.76,0.22,1.01c0.14,0.17,0.4,0.37,0.87,0.4c0.25,0.01,0.49,0.01,0.74,0.01
			l0.4,0c-0.06,0.35-0.13,0.69-0.2,1.03c-0.01,0.04-0.02,0.07-0.01,0.08c-0.92,0.32-1.66,0.31-2.35-0.03
			c-0.45-0.22-0.73-0.69-0.88-1.52c-0.14-0.75-0.15-1.56-0.04-2.62c0.18-1.73,0.58-3.05,1.26-4.15c0.48-0.78,1.07-1.23,1.79-1.37
			c0.67-0.13,1.4-0.13,2.22,0c0.25,0.04,0.49,0.08,0.74,0.12c0.34,0.06,0.68,0.11,1.02,0.16c0.43,0.06,0.82-0.08,1.1-0.36
			c-0.26,0.29-0.42,0.67-0.42,1.1c0,0.88,0.68,1.6,1.51,1.6h0.46c0.65,0,1.2-0.43,1.41-1.04c0.22,0.59,0.77,1.02,1.42,1.02
			l1.55-0.02l1.11,0.01c0.07,0.02,0.15,0.12,0.15,0.26c0,0.14-0.07,0.28-0.2,0.28l-3.06,0.02c-0.84,0-1.53,0.74-1.53,1.65
			c0,0.91,0.69,1.65,1.53,1.65l1.07-0.01l0.09,0.04c0.05,0.03,0.09,0.11,0.09,0.22c0,0.13-0.08,0.24-0.16,0.25l-3.57,0
			c-0.34,0-0.64,0.12-0.88,0.32l0.02-0.11c0.16-0.81,0.32-1.61,0.47-2.42C27.17-542.32,27.03-542.64,26.89-542.82z M45.84-542.78
			c-0.28,2.15-0.75,3.98-1.42,5.6c-0.61,1.46-1.33,2.47-2.29,3.19c-0.77,0.58-1.66,0.95-2.64,1.11c-0.49,0.08-0.98,0.12-1.37,0.15
			l-0.3,0.03c-1.25-0.03-2.38-0.09-3.4-0.48c-0.51-0.19-0.95-0.46-1.3-0.79l-0.18-0.16H32.7l-1.47,0c-0.14-0.01-0.26-0.16-0.26-0.34
			c0-0.09,0.04-0.19,0.11-0.26c0.03-0.03,0.07-0.06,0.09-0.06l0.63,0c0.73-0.06,1.3-0.72,1.3-1.49c0-0.74-0.52-1.38-1.18-1.48
			c-0.08-0.02-0.16-0.02-0.24-0.02h-4.22c-0.12,0-0.21-0.13-0.21-0.29c0-0.16,0.1-0.3,0.21-0.3h3.57c0.06,0,0.12-0.01,0.16-0.01
			l0.06-0.01c0.66-0.12,1.16-0.75,1.16-1.46c0-0.57-0.31-1.1-0.75-1.31l-0.16-0.08l-0.04-0.01c-0.05-0.02-0.09-0.03-0.14-0.04
			c-0.1-0.02-0.21-0.03-0.31-0.03h-1.08c-0.17,0-0.31-0.19-0.31-0.42c0-0.23,0.14-0.43,0.31-0.43l3.06-0.02
			c0.8,0,1.42-0.66,1.42-1.5c0-0.66-0.4-1.23-0.98-1.42c-0.15-0.05-0.29-0.08-0.44-0.08l-2.61,0.02c-0.15,0-0.29-0.17-0.29-0.36
			c0-0.21,0.13-0.38,0.29-0.38h1.47l0.35-0.02l0.06-0.06c0.38-0.12,0.7-0.41,0.87-0.79c0.09-0.2,0.14-0.41,0.14-0.64l-0.01-0.15
			c-0.06-0.62-0.48-1.14-1.05-1.29l-0.07-0.06h-2.33c-0.09,0-0.21-0.11-0.21-0.29s0.12-0.29,0.21-0.29h6.31l0.14-0.09
			c0.55-0.33,1.17-0.56,1.84-0.69c1.82-0.34,3.56-0.27,5.19,0.2c1.54,0.45,2.44,1.6,2.66,3.41v0
			C46.13-545.35,46-544.02,45.84-542.78z"/>
										</g>
									</g>
									<g>
										<path d="M24.35,44.67c-7.05,0-12.79-5.74-12.79-12.79c-0.29-10.07,11.94-21.37,12.46-21.84l0.34-0.31l0.34,0.31
		c0.52,0.47,12.61,11.65,12.46,21.68l0,0.16C37.15,38.93,31.41,44.67,24.35,44.67z M24.35,11.1c-1.99,1.92-12.05,12.06-11.79,20.76
		v0.01c0,6.5,5.29,11.79,11.79,11.79c6.34,0,11.52-5.02,11.78-11.29h0l0.01-0.51C36.4,23.15,26.34,13.01,24.35,11.1z"/>
									</g>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str3}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/explore" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{ "enableBackground": "new 0 0 48.7 54.4" }} xmlSpace="preserve">
									<g>
										<g>
											<path d="M24.35,16.17c-9.25,0-16.78,7.53-16.78,16.78s7.53,16.79,16.78,16.79s16.78-7.53,16.78-16.79
			S33.6,16.17,24.35,16.17z M25.1,48.7v-2.79h-1.5v2.79c-8.1-0.38-14.61-6.89-15-15h2.79v-1.5H8.6c0.38-8.1,6.89-14.61,15-15v2.69
			h1.5v-2.69c8.1,0.38,14.61,6.89,15,15h-2.69v1.5h2.69C39.71,41.81,33.2,48.32,25.1,48.7z M22.38,7.95l-0.19-1.56l0.04-0.01
			l0.59,1.26l3.11,5.47h1.57V4.66H26.1v5.16l0.18,1.56l-0.04,0.01l-0.58-1.26l-3.08-5.47H21v8.44h1.38V7.95z M23.6,32.96h1.5v-10.5
			h-1.5V32.96z"/>
										</g>
									</g>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str4}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/go" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{ "enableBackground": "new 0 0 48.7 54.4" }} xmlSpace="preserve">
									<g>
										<g>
											<path d="M39.36,21.37c-1.13-0.5-2.25-0.45-3.25,0.13c-0.68,0.4-1.18,1.04-1.58,2.01c-0.93,2.29-1.17,4.69-1.28,6.6
			l0.02,0.32c0.02,0.28,0.03,0.56,0.07,0.84c0.09,0.64,0.31,1.53,1.18,1.92c0.51,0.23,1.02,0.34,1.53,0.34
			c0.59,0,1.16-0.15,1.71-0.46c0.94-0.52,1.34-1.49,1.64-2.35c0.51-1.44,0.86-3.07,1.06-4.98c0.08-0.72,0.1-1.5,0.06-2.46
			C40.5,22.38,40.07,21.68,39.36,21.37z M39.26,25.61L39.26,25.61c-0.2,1.82-0.52,3.36-1,4.71c-0.25,0.71-0.52,1.38-1.08,1.69
			c-0.68,0.38-1.41,0.4-2.15,0.07c-0.18-0.08-0.37-0.26-0.48-0.98c-0.03-0.24-0.05-0.49-0.06-0.73l-0.02-0.26
			c0.1-1.74,0.33-4.01,1.19-6.13c0.29-0.71,0.63-1.16,1.06-1.42c0.35-0.2,0.71-0.31,1.09-0.31c0.33,0,0.68,0.08,1.05,0.24
			c0.37,0.16,0.44,0.6,0.45,0.85C39.35,24.22,39.33,24.95,39.26,25.61z M45.24,22.03c-0.28-2.27-1.53-3.85-3.53-4.43
			c-1.81-0.53-3.75-0.6-5.75-0.23c-0.75,0.14-1.45,0.4-2.09,0.75h-5.98c-0.79,0-1.44,0.68-1.44,1.52s0.64,1.52,1.44,1.52h1.88
			l0.19,0.02c0.07,0.01,0.16,0.11,0.16,0.25l0,0.04c0,0.03-0.01,0.07-0.03,0.1c-0.03,0.07-0.09,0.12-0.14,0.13l-1.49,0.02
			c-0.64,0-1.19,0.43-1.41,1.03c-0.22-0.6-0.77-1.03-1.41-1.03h-0.46c-0.4,0-0.76,0.17-1.03,0.44c0.08-0.1,0.16-0.2,0.22-0.32
			c0.44-0.94,0.69-1.82,0.77-2.67c0.07-0.67-0.23-1.2-0.8-1.47c-0.31-0.14-0.63-0.25-0.94-0.31c-2-0.41-4.01-0.44-5.97-0.07
			c-1.23,0.23-2.33,0.74-3.28,1.51h-4.4c-0.76,0-1.38,0.66-1.38,1.46c0,0.81,0.62,1.46,1.38,1.46h1.76l0.17,0.02
			c0.04,0,0.11,0.08,0.11,0.18l0,0.05c0,0.03-0.01,0.05-0.02,0.08l-0.11,0.1l-0.02,0c-0.25,0.01-1.37,0.01-1.37,0.01
			c-0.59,0-1.09,0.37-1.32,0.9c-0.08-0.18-0.18-0.35-0.32-0.48c-0.27-0.27-0.63-0.42-1-0.42c0,0,0,0,0,0H7.19
			c-0.8,0-1.45,0.69-1.45,1.54c0,0.85,0.65,1.54,1.45,1.54h0.43c0.58,0,1.09-0.38,1.32-0.91c0.23,0.52,0.73,0.89,1.33,0.89
			l2.47-0.01c0.05,0.02,0.11,0.1,0.11,0.21c0,0.11-0.06,0.22-0.15,0.22l-2.86,0.02c-0.81,0-1.47,0.71-1.47,1.58
			c0,0.87,0.66,1.58,1.47,1.58l1.05,0.01c0.03,0.02,0.08,0.08,0.08,0.19c0,0.11-0.07,0.19-0.11,0.2H7.53
			c-0.62,0-1.12,0.41-1.31,0.99c-0.06-0.2-0.16-0.39-0.3-0.55c-0.26-0.29-0.62-0.45-1-0.45H4.76c-0.76,0-1.39,0.66-1.39,1.46
			c0,0.38,0.14,0.74,0.39,1.02c0.27,0.29,0.62,0.44,1,0.44h0.16c0.61,0,1.12-0.41,1.31-0.99c0.19,0.57,0.7,0.98,1.3,0.98l3.96,0
			c0.04,0.01,0.13,0.09,0.13,0.22c0,0.13-0.08,0.21-0.14,0.22l-0.59,0.01c-0.3,0.03-0.57,0.16-0.79,0.38
			c-0.3,0.28-0.46,0.68-0.46,1.1c0,0.81,0.6,1.47,1.4,1.5h0.6c0.24,0.25,0.5,0.48,0.8,0.69c0.92,0.64,1.99,0.99,3.27,1.06
			c0.33,0.02,0.67,0.03,1.02,0.03c0.92,0,1.86-0.06,2.82-0.19c1.14-0.15,2-0.37,2.79-0.7c0.69-0.29,1.1-0.83,1.26-1.64
			c0.21-1.11,0.44-2.21,0.66-3.32c0.24,0.48,0.71,0.81,1.26,0.81l4.24,0c0.07,0.01,0.17,0.12,0.17,0.27c0,0.15-0.1,0.27-0.19,0.27
			l-0.55,0c-0.33,0.02-0.65,0.16-0.9,0.4c-0.31,0.3-0.48,0.71-0.48,1.14c0,0.84,0.63,1.53,1.45,1.56h1.26
			c0.43,0.36,0.94,0.66,1.52,0.87c1.2,0.45,2.43,0.52,3.87,0.56l0.36-0.03c0.41-0.03,0.94-0.08,1.46-0.16
			c1.18-0.18,2.26-0.63,3.19-1.34c1.12-0.84,2-2.05,2.68-3.69c0.72-1.72,1.21-3.66,1.5-5.91C45.29,24.92,45.42,23.5,45.24,22.03z
			 M7.63,24.02H7.19c-0.1,0-0.23-0.14-0.23-0.32c0-0.17,0.11-0.32,0.23-0.32h0.43h0c0.06,0,0.11,0.03,0.13,0.06
			c0.06,0.06,0.1,0.16,0.1,0.26C7.86,23.88,7.73,24.02,7.63,24.02z M4.92,30.92H4.76c-0.04,0-0.08-0.03-0.09-0.04
			c-0.05-0.05-0.07-0.12-0.07-0.2c0-0.14,0.09-0.24,0.16-0.24h0.16c0.04,0,0.08,0.03,0.09,0.05c0.05,0.05,0.07,0.12,0.07,0.19
			C5.08,30.82,5,30.92,4.92,30.92z M25.17,22.93h0.46c0.16,0,0.29,0.17,0.29,0.38c0,0.2-0.14,0.38-0.29,0.38h-0.46
			c-0.15,0-0.29-0.18-0.29-0.38C24.88,23.1,25.01,22.93,25.17,22.93z M23.94,26.81L23.94,26.81c-0.15,0.8-0.31,1.6-0.47,2.4
			l-0.35,1.71c-0.25,1.21-0.49,2.42-0.73,3.64c-0.09,0.47-0.28,0.64-0.53,0.75c-0.69,0.29-1.46,0.48-2.48,0.62
			c-1.25,0.17-2.46,0.22-3.61,0.16c-1.05-0.06-1.91-0.33-2.64-0.84c-0.29-0.2-0.55-0.44-0.76-0.69l-0.18-0.22h-0.29l-0.85,0
			c-0.11,0-0.2-0.13-0.2-0.28c0-0.08,0.03-0.16,0.14-0.26l0.59,0c0.7-0.06,1.25-0.69,1.25-1.44c0-0.71-0.5-1.33-1.13-1.42
			c-0.08-0.02-0.15-0.02-0.24-0.02H7.53c-0.09,0-0.16-0.11-0.16-0.23c0-0.13,0.07-0.24,0.16-0.24h3.34c0.1,0,0.18-0.02,0.21-0.02
			c0.64-0.11,1.13-0.71,1.13-1.4c0-0.55-0.3-1.05-0.75-1.28c-0.09-0.05-0.19-0.09-0.3-0.11c-0.1-0.02-0.2-0.03-0.3-0.03H9.85
			c-0.14,0-0.25-0.16-0.25-0.36c0-0.19,0.11-0.36,0.25-0.36l2.86-0.02c0.77,0,1.37-0.63,1.37-1.45c0-0.63-0.38-1.18-0.95-1.37
			c-0.14-0.05-0.28-0.07-0.42-0.07L10.27,24c-0.12,0-0.23-0.14-0.23-0.3c0-0.17,0.11-0.32,0.23-0.32c0.88,0,1.31,0,1.55-0.03l0,0
			c0.22-0.03,0.42-0.11,0.58-0.22c0.21-0.14,0.38-0.35,0.49-0.59c0.09-0.19,0.13-0.4,0.13-0.62L13,21.83c-0.05-0.6-0.46-1.11-1-1.26
			l-0.07-0.06h-2.2c-0.07,0-0.16-0.09-0.16-0.24c0-0.15,0.09-0.24,0.16-0.24h3.9l-0.09,0.92l1.16-1.03c0.85-0.75,1.8-1.21,2.93-1.43
			c1.8-0.33,3.65-0.31,5.5,0.07c0.22,0.04,0.45,0.12,0.68,0.23c0.08,0.04,0.11,0.05,0.1,0.23c-0.07,0.72-0.29,1.47-0.67,2.28
			c-0.02,0.05-0.03,0.06-0.1,0.05c-0.33-0.04-0.66-0.1-0.99-0.15c-0.25-0.04-0.5-0.08-0.75-0.12c-0.96-0.15-1.82-0.14-2.63,0.01
			c-1.06,0.21-1.94,0.85-2.6,1.93c-0.78,1.26-1.23,2.75-1.43,4.67c-0.12,1.17-0.1,2.11,0.06,2.97c0.14,0.78,0.45,1.87,1.54,2.4
			c1,0.49,2.09,0.52,3.34,0.07c0.4-0.15,0.67-0.5,0.76-0.99c0.08-0.39,0.15-0.78,0.23-1.18l0.25-1.3l-1.87,0
			c-0.2,0-0.39,0-0.62-0.01c0,0,0,0,0,0c0.05-0.34,0.12-0.68,0.19-1.02l0.32-1.57c0.04-0.19,0.07-0.26,0.07-0.26
			c0.02-0.01,0.07-0.02,0.19-0.02l3.09,0l1.55,0c0.03,0,0.07,0,0.13,0.01C23.94,26.79,23.94,26.8,23.94,26.81z M24.95,26.03
			c-0.13-0.18-0.39-0.41-0.85-0.46c-0.1-0.01-0.2-0.02-0.29-0.02l-1.54,0l-3.09,0c-1.07,0-1.34,0.68-1.46,1.27l-0.32,1.57
			c-0.07,0.36-0.14,0.71-0.19,1.07c-0.06,0.42,0.01,0.76,0.22,1.01c0.14,0.17,0.4,0.37,0.87,0.4c0.25,0.01,0.49,0.01,0.74,0.01
			l0.4,0c-0.06,0.35-0.13,0.69-0.2,1.03c-0.01,0.04-0.02,0.07-0.01,0.08c-0.92,0.32-1.66,0.31-2.35-0.03
			c-0.45-0.22-0.73-0.69-0.88-1.52c-0.14-0.75-0.15-1.56-0.04-2.62c0.18-1.73,0.58-3.05,1.26-4.15c0.48-0.78,1.07-1.23,1.79-1.37
			c0.67-0.13,1.4-0.13,2.22,0c0.25,0.04,0.49,0.08,0.74,0.12c0.34,0.06,0.68,0.11,1.02,0.16c0.43,0.06,0.82-0.08,1.1-0.36
			c-0.26,0.29-0.42,0.67-0.42,1.1c0,0.88,0.68,1.6,1.51,1.6h0.46c0.65,0,1.2-0.43,1.41-1.04c0.22,0.59,0.77,1.02,1.42,1.02
			l1.55-0.02l1.11,0.01c0.07,0.02,0.15,0.12,0.15,0.26c0,0.14-0.07,0.28-0.2,0.28L28,25.44c-0.84,0-1.53,0.74-1.53,1.65
			c0,0.91,0.69,1.65,1.53,1.65l1.07-0.01l0.09,0.04c0.05,0.03,0.09,0.11,0.09,0.22c0,0.13-0.08,0.24-0.16,0.25l-3.57,0
			c-0.34,0-0.64,0.12-0.88,0.32l0.02-0.11c0.16-0.81,0.32-1.61,0.47-2.42C25.23,26.54,25.09,26.22,24.95,26.03z M43.9,26.08
			c-0.28,2.15-0.75,3.98-1.42,5.6c-0.61,1.46-1.33,2.47-2.29,3.19c-0.77,0.58-1.66,0.95-2.64,1.11c-0.49,0.08-0.98,0.12-1.37,0.15
			l-0.3,0.03c-1.25-0.03-2.38-0.09-3.4-0.48c-0.51-0.19-0.95-0.46-1.3-0.79L31,34.71h-0.24l-1.47,0c-0.14-0.01-0.26-0.16-0.26-0.34
			c0-0.09,0.04-0.19,0.11-0.26c0.03-0.03,0.07-0.06,0.09-0.06l0.63,0c0.73-0.06,1.3-0.72,1.3-1.49c0-0.74-0.52-1.38-1.18-1.48
			c-0.08-0.02-0.16-0.02-0.24-0.02h-4.22c-0.12,0-0.21-0.13-0.21-0.29c0-0.16,0.1-0.3,0.21-0.3h3.57c0.06,0,0.12-0.01,0.16-0.01
			l0.06-0.01c0.66-0.12,1.16-0.75,1.16-1.46c0-0.57-0.31-1.1-0.75-1.31l-0.16-0.08l-0.04-0.01c-0.05-0.02-0.09-0.03-0.14-0.04
			c-0.1-0.02-0.21-0.03-0.31-0.03H28c-0.17,0-0.31-0.19-0.31-0.42c0-0.23,0.14-0.43,0.31-0.43l3.06-0.02c0.8,0,1.42-0.66,1.42-1.5
			c0-0.66-0.4-1.23-0.98-1.42c-0.15-0.05-0.29-0.08-0.44-0.08l-2.61,0.02c-0.15,0-0.29-0.17-0.29-0.36c0-0.21,0.13-0.38,0.29-0.38
			h1.47l0.35-0.02l0.06-0.06c0.38-0.12,0.7-0.41,0.87-0.79c0.09-0.2,0.14-0.41,0.14-0.64l-0.01-0.15c-0.06-0.62-0.48-1.14-1.05-1.29
			l-0.07-0.06h-2.33c-0.09,0-0.21-0.11-0.21-0.29s0.12-0.29,0.21-0.29h6.31l0.14-0.09c0.55-0.33,1.17-0.56,1.84-0.69
			c1.82-0.34,3.56-0.27,5.19,0.2c1.54,0.45,2.44,1.6,2.66,3.41v0C44.19,23.51,44.06,24.84,43.9,26.08z"/>
										</g>
									</g>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str5}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/kids" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{ "enableBackground": "new 0 0 48.7 54.4" }} xmlSpace="preserve">
									<g>
										<path d="M30.62,54.4l-12.62-0.02c-0.06-0.02-1.53-0.46-1.53-2.53V33.14c-0.11-0.41-0.29-1.85,0.5-6.03
		c0.8-4.26,3.54-19.63,4.11-22.84h-0.8V2.57h0.54l0.13-0.21L21.28,0h6.14l0.33,2.37l0.17,0.21h0.5v1.69h-0.8
		c0.57,3.21,3.31,18.58,4.11,22.84l0,0c0.78,4.18,0.6,5.62,0.5,6.03v18.71c0,2.07-1.47,2.52-1.53,2.53L30.62,54.4z M18.23,53.4
		h12.23c0.18-0.08,0.76-0.42,0.76-1.55l0.03-18.96c0.01-0.03,0.32-1.14-0.51-5.6l0,0c-0.89-4.75-4.19-23.25-4.22-23.44l-0.1-0.59
		h0.71l-0.37-0.66L26.55,1h-4.4l-0.3,1.81l-0.29,0.46h0.71l-0.1,0.59c-0.03,0.19-3.33,18.69-4.22,23.44
		c-0.84,4.45-0.52,5.57-0.5,5.61l0.03,0.16v18.78C17.47,52.98,18.06,53.32,18.23,53.4z"/>
									</g>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str6}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/contacto" className="" rel="noopener">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 48.7 54.4" style={{
										"enableBackground": "new 0 0 48.7 54.4"
									}} xmlSpace="preserve" >
									<path d="M44.56,13.12L19.8,33.77l0.02,0.01l-2.3,5.2l-1.05-7.36l27.27-18.94L4.14,26.95l11.34,4.76l1.43,10.01
	l7.33-5.97l10.22,4.58L44.56,13.12z M36.92,16.2L15.89,30.8l-9-3.78L36.92,16.2z M18.58,39.08l2.16-4.89l2.48,1.11L18.58,39.08z
	 M21.67,33.51l20.64-17.22l-8.43,22.7L21.67,33.51z"/>
								</svg>
								<span>{props.fastio.langKeys.nav[props.fastio.lang].str7}</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/project2020" className="" rel="noopener">
								<div className="selected"><a style={{ "lineHeight": "33.51px", "fontSize": "13px" }}>2020</a></div>
								<span className="list">
									Compete 2020
								</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}
export default Nav;