import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const Go = (props) => {
    let dynClaimSrc = [];
    switch (props.fastio.lang) {
        case 'en':
            dynClaimSrc = [
                "flexivel_en.svg",
                "forte_en.svg",
                "imparavel_en.svg",
                "melhor_en.svg",
                "resiliente_en.svg",
                "veloz_en.svg"
            ]
            break;
        case 'pt':
            dynClaimSrc = [
                "flexivel.svg",
                "forte.svg",
                "imparavel.svg",
                "melhor.svg",
                "resiliente.svg",
                "veloz.svg"
            ]
            break;
        case 'fr':
            dynClaimSrc = [
                "flexivel_fr.svg",
                "forte_fr.svg",
                "imparavel_fr.svg",
                "melhor_fr.svg",
                "resiliente_fr.svg",
                "veloz_fr.svg"
            ]
            break;
        case 'es':
            dynClaimSrc = [
                "flexivel_es.svg",
                "forte_es.svg",
                "imparavel_es.svg",
                "melhor_es.svg",
                "resiliente_es.svg",
                "veloz_es.svg"
            ]
            break;
        default:
            dynClaimSrc = [
                "flexivel.svg",
                "forte.svg",
                "imparavel.svg",
                "melhor.svg",
                "resiliente.svg",
                "veloz.svg"
            ]
    }

    let [claimTxt1, setClaimTxt1] = useState(0);
    let [claimTxt2, setClaimTxt2] = useState(0);

    let [claimSrc1, setClaimSrc1] = useState(1);
    let [claimImg1, setClaimImg1] = useState(0);
    let [claimImg2, setClaimImg2] = useState(0);

    let [bottlePos, setBottlePos] = useState(0);

    const setVideo = (path) => {
        var ext = path.split('.')[1];
        return require('../../video/' + path.split('.')[0] + "." + ext);
    }

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/go/' + path.split('.')[0] + "." + ext);
    }

    const loadAnim = () => {
        if (document.documentElement.scrollTop == 0) {
            setTimeout(() => {
                setClaimImg1(1)
                setClaimTxt1(1)
            }, 1000)
        }
    }

    let src = 0;
    const dynClaim = () => {
        src < 5 ? src++ : src = 0;
        setClaimSrc1(src)
    }

    const rainMachine = () => {

        const position = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
        const rainMachine = document.getElementsByClassName("rain-spot");
        const drop = document.createElement("div");
        const drop2 = document.createElement("div");

        drop.classList.add("rain-drop");
        drop.style.height = Math.floor(Math.random() * (40 - 15 + 1)) + 15 + 'px';
        drop.style.left = position[Math.floor(Math.random() * (9 - 1 + 1)) + 1] + '%';
        rainMachine[0].appendChild(drop);


        setTimeout(() => {
            drop.classList.add("go");
        }, 500)
        setTimeout(() => {
            drop.remove();
        }, 4500)


        if (window.matchMedia('(min-width: 800px)').matches) {
            const rainMachine2 = document.getElementsByClassName("rain-spot2");

            drop2.classList.add("rain-drop");
            drop2.style.height = Math.floor(Math.random() * (80 - 15 + 1)) + 15 + 'px';
            drop2.style.left = position[Math.floor(Math.random() * (9 - 1 + 1)) + 1] + '%';
            rainMachine2[0].appendChild(drop2);
            setTimeout(() => {
                drop2.classList.add("go");
            }, 500)
            setTimeout(() => {
                drop2.remove();
            }, 10500)
        }
    }

    const scrollCatch = () => {
        const video = document.getElementById("goVideo");
        let scrolled = document.documentElement.scrollTop;
        let animation = parseInt(scrolled * 100 / (window.innerHeight));

        let videoProps = video.getBoundingClientRect();

        let text1 = Math.abs(animation < 20 ? 1 : animation > 40 ? 0 : (animation - 20) / 10 + -1);
        let text2 = animation < 50 ? 0 : animation > 70 ? 1 : (animation - 50) / 10;

        let image1 = Math.abs(animation < 20 ? 1 : animation > 40 ? 0 : (animation - 20) / 10 + -1);
        let image2 = animation < 50 ? 0 : animation > 70 ? 1 : (animation - 50) / 10;


        videoProps.top + (videoProps.height / 2) < window.innerHeight && videoProps.bottom >= (videoProps.height / 2) ? video.play() : video.pause();

        setClaimTxt1(text1);
        setClaimTxt2(text2);

        setClaimImg1(image1);
        setClaimImg2(image2);

        if (window.matchMedia('(min-width: 800px)').matches) {
            const dynBottle = document.getElementById("dynBottle");
            const bottleContainer = document.getElementById("bottleContainer");
            let dynBottleProps = dynBottle.getBoundingClientRect();
            let bottleContainerProps = bottleContainer.getBoundingClientRect();
            let bottleAnimation = (bottleContainerProps.top * -1) + (window.innerHeight - dynBottleProps.height) / 2
            let bottle = bottleAnimation >= 0 ? bottleAnimation >= bottleContainerProps.height - dynBottleProps.height ? bottleContainerProps.height - dynBottleProps.height - 50 : bottleAnimation : 50;
            setBottlePos(bottle)

        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader()
        window.addEventListener('scroll', scrollCatch);
        const startRain = setInterval(rainMachine, 200);
        rainMachine();
        const startDynClaim = setInterval(dynClaim, 2500);
        return () => {
            // This function will be called just before the component unmounts.
            // Remove listeners, subscriptions, etc.    
            clearInterval(startRain);
            clearInterval(startDynClaim);
            window.removeEventListener('scroll', scrollCatch);
        };
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://fastio.pt/go" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>Fastio Go - Água do Fastio</title>
            </Helmet>
            <section className="fastio-go">
                {/* <div className="header-helper"></div> */}
                <div className="claim-canvas">
                    <div className="claim">
                        <div className="read-filter-claim"></div>
                        <div className="container">
                            <div className="content">
                                <div className="rain-spot">
                                    <div className="logo">
                                        <img src={setImage("claims/logo_fastio_go.svg")} />
                                    </div>
                                </div>
                                <div className="text clearfix" style={{ "opacity": claimTxt1 }}>
                                    <p>{props.fastio.langKeys.go.claim[props.fastio.lang].str1}</p>
                                </div>
                                <div className="text clearfix" style={{ "opacity": claimTxt2 }}>
                                    <p>{props.fastio.langKeys.go.claim[props.fastio.lang].str2[0]}<span>{props.fastio.langKeys.go.claim[props.fastio.lang].str2[1]}</span>.
                                    </p>
                                </div>
                                <div className="image clearfix" style={{ "opacity": claimImg1 }} ><img className="svg" style={{ "opacity": 1 }} src={setImage("claims/" + dynClaimSrc[claimSrc1])} /></div>
                                <div className="image" style={{ "opacity": claimImg2 }}><img src={setImage('moments/bottle_fastio_go.png')} /></div>
                            </div>
                        </div>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            className="scroll-icon" viewBox="0 0 25.25 64.24" style={{ "enableBackground": "new 0 0 25.25 64.24" }} xmlSpace="preserve">
                            <style type="text/css">
                            </style>
                            <g>
                                <polygon style={{ "fill": "#FFFFFF" }} points="12.63,48.51 21.71,48.51 17.17,56.38 12.63,64.24 8.09,56.38 3.55,48.51 	" />
                                <g>
                                    <path style={{ "fill": "#FFFFFF" }} d="M12.63,41.59C5.66,41.59,0,35.92,0,28.96V12.63C0,5.66,5.66,0,12.63,0c6.96,0,12.63,5.66,12.63,12.63v16.33
                                    C25.25,35.92,19.59,41.59,12.63,41.59z M12.63,1C6.22,1,1,6.22,1,12.63v16.33c0,6.41,5.22,11.63,11.63,11.63
			s11.63-5.22,11.63-11.63V12.63C24.25,6.22,19.04,1,12.63,1z"/>
                                </g>
                                <path style={{ "fill": "#FFFFFF" }} d="M12.63,9.19c-1.13,0-2.05,0.92-2.05,2.05v2.77c0,1.13,0.92,2.05,2.05,2.05c1.13,0,2.05-0.92,2.05-2.05v-2.77
		C14.68,10.11,13.76,9.19,12.63,9.19z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="video">
                    <div className="container">
                        <div className="content">
                            <video id="goVideo" width="100%" height="auto" loop muted>
                                <source src={setVideo('govideo.mp4')} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="read-filter"></div>
                            <div className="text">
                                <h2>{props.fastio.langKeys.go.video[props.fastio.lang].str1[0]}<br />
                                    {props.fastio.langKeys.go.video[props.fastio.lang].str1[1]} <span>{props.fastio.langKeys.go.video[props.fastio.lang].str1[2]}</span>.
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="moment one">
                        <div className="container">
                            <div className="content">
                                <div className="asset">
                                    <img src={setImage("moments/bottle_body.png")} />
                                </div>
                                <div className="text">
                                    <h2>{props.fastio.langKeys.go.moment[props.fastio.lang].str1}
                                    </h2>
                                    <p>{props.fastio.langKeys.go.moment[props.fastio.lang].str2[0]} <span>{props.fastio.langKeys.go.moment[props.fastio.lang].str2[1]}</span>.
                                    </p>
                                </div>
                                <div className="image"></div>
                            </div>
                        </div>
                    </div>
                    <div className="moment two">
                        <div className="container">
                            <div className="content">
                                <div className="asset">
                                    <img src={setImage("moments/bottle_capsule.png")} />
                                </div>
                                <div className="text">
                                    <h2>{props.fastio.langKeys.go.moment[props.fastio.lang].str3}
                                    </h2>
                                    <p>{props.fastio.langKeys.go.moment[props.fastio.lang].str4[0]}<span>{props.fastio.langKeys.go.moment[props.fastio.lang].str4[1]}</span>
                                    </p>
                                </div>
                                <div className="image"></div>
                            </div>
                        </div>
                    </div>
                    <div className="rain-spot2">
                        <div id="bottleContainer" className="bottle">
                            <img id="dynBottle" style={{ "top": bottlePos }} src={setImage("moments/bottle_fastio_go.png")} />
                        </div>
                    </div>
                </div>
                <div className="moment three">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>
                                </h2>
                                <p><span>{props.fastio.langKeys.go.moment[props.fastio.lang].str5[0]}</span><br />
                                    {props.fastio.langKeys.go.moment[props.fastio.lang].str5[1]}
                                </p>
                            </div>
                            <div className="image"><img src={setImage('moments/bottle_fastio_go.png')} /></div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}
export default Go;