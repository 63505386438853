import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

let game = {
    winner: '',
    scene: {
        endTime: 0,
        state: {
            initial: {
                class: 'initial',
                speed: 0,
                btnClass: ''
            },
            ready: {
                class: '',
                speed: 0,
                btnClass: 'hidden'
            },
            run: {
                class: 'run',
                speed: 20,
                btnClass: 'hidden'

            },
            end: {
                class: 'run',
                speed: 0,
                btnClass: 'hidden'
            },
            reset: {
                class: '',
                speed: 3,
                btnClass: 'hidden'
            }
        }
    },
    animals: {
        // borboleta: {
        //     id: "borboleta",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        // crocodilo: {
        //     id: "crocodilo",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        // mosca: {
        //     id: "mosca",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        // ovelha: {
        //     id: "ovelha",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        cabra: {
            id: "cabra",
            name: {
                "pt": "Cabra",
                "en": "Goat",
                "es": "Cabra",
                "fr": "Chèvre"

            },
            state: {
                initial: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                ready: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                run: {
                    class: 'run',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 20
                },
                end: {
                    class: 'run',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 3
                },
                reset: {
                    class: 'flipped',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 3
                }
            }
        },
        // veado: {
        //     id: "veado",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        cavalo: {
            id: "cavalo",
            name: {
                "pt": "Cavalo",
                "en": "Horse",
                "es": "Caballo",
                "fr": "Cheval"

            },
            state: {
                initial: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                ready: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                run: {
                    class: 'run',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 20
                },
                end: {
                    class: 'run',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 3
                },
                reset: {
                    class: 'flipped',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 3
                }
            }
        },
        // zebra: {
        //     id: "zebra",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        // pato: {
        //     id: "pato",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        menina: {
            id: "menina",
            name: {
                "pt": "Menina",
                "en": "Girl",
                "es": "Niña",
                "fr": "Fille"

            },
            state: {
                initial: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                ready: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                run: {
                    class: 'run',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 20
                },
                end: {
                    class: 'run',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 3
                },
                reset: {
                    class: 'flipped',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 3
                }
            }
        },
        esquilo: {
            id: "esquilo",
            name: {
                "pt": "Esquilo",
                "en": "Squirrel",
                "es": "Ardilla",
                "fr": "Écureuil"

            },
            state: {
                initial: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                ready: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                run: {
                    class: 'run',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 20
                },
                end: {
                    class: 'run',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 3
                },
                reset: {
                    class: 'flipped',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 3
                }
            }
        },
        // passaroazul: {
        //     id: "passaroazul",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 20
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 20
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // },
        aguia: {
            id: "aguia",
            name: {
                "pt": "Águia",
                "en": "Eagle",
                "es": "Águila",
                "fr": "Aigle"

            },
            state: {
                initial: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                ready: {
                    class: '',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 20
                },
                run: {
                    class: 'run',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 20
                },
                end: {
                    class: 'run',
                    src: {
                        img: '_estatico',
                        ext: 'png'
                    },
                    speed: 3
                },
                reset: {
                    class: 'flipped',
                    src: {
                        img: '',
                        ext: 'gif'
                    },
                    speed: 3
                }
            }
        }
        //, caranguejo: {
        //     id: "caranguejo",
        //     state: {
        //         initial: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 0
        //         },
        //         ready: {
        //             class: '',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 0
        //         },
        //         run: {
        //             class: 'run',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 0
        //         },
        //         end: {
        //             class: 'run',
        //             src: {
        //                 img: '_estatico',
        //                 ext: 'png'
        //             },
        //             speed: 3
        //         },
        //         reset: {
        //             class: 'flipped',
        //             src: {
        //                 img: '',
        //                 ext: 'gif'
        //             },
        //             speed: 3
        //         }
        //     }
        // }
    }
}

const Kids = (props) => {

    let [scene, setScene] = useState(game.scene.state.initial);

    let [menina, setMenina] = useState(game.animals.menina.state.initial);
    let [cavalo, setCavalo] = useState(game.animals.cavalo.state.initial);
    let [cabra, setCabra] = useState(game.animals.cabra.state.initial);
    let [aguia, setAguia] = useState(game.animals.aguia.state.initial);
    // let [borboleta, setBorboleta] = useState(game.animals.borboleta.state.initial);
    // let [crocodilo, setCrocodilo] = useState(game.animals.crocodilo.state.initial);
    // let [mosca, setMosca] = useState(game.animals.mosca.state.initial);
    // let [ovelha, setOvelha] = useState(game.animals.ovelha.state.initial);
    // let [veado, setVeado] = useState(game.animals.veado.state.initial);
    // let [zebra, setZebra] = useState(game.animals.zebra.state.initial);
    // let [pato, setPato] = useState(game.animals.pato.state.initial);
    let [esquilo, setEsquilo] = useState(game.animals.esquilo.state.initial);
    // let [passaroazul, setPassaroazul] = useState(game.animals.passaroazul.state.initial);
    // let [caranguejo, setCaranguejo] = useState(game.animals.caranguejo.state.initial);

    let [finishLine, setFinishLine] = useState(false);

    let [modal, setModal] = useState({ display: false, type: 'pick' });
    let [modalText, setModalText] = useState({ winnerAnimal: '', win: false });

    const useStateSetAnimals = [setCabra, setCavalo, setMenina, setEsquilo, setAguia]
    //const useStateAnimals = [cabra, cavalo, menina, esquilo, aguia]

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/kids/' + path.split('.')[0] + "." + ext);
    }

    const loadAnim = () => {
        let i = 0;
        let elems = document.getElementsByClassName("anim");
        for (let key of elems) {
            key.style.transitionDelay = i < 9 ? ('0.' + i + 's') : (i / 10 + 's');
            setTimeout(() => {
                key.style.opacity = 1;
            }, 100)
            i++
        }
    }
    const rainMachine = () => {

        const position = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
        const rainMachine = document.getElementsByClassName("kids");
        const drop = document.createElement("div");

        drop.classList.add("rain-drop");
        drop.style.top = position[Math.floor(Math.random() * (9 - 1 + 1)) + 1] + '%';

        rainMachine[0].appendChild(drop);

        setTimeout(() => {
            drop.classList.add("go");
        }, 500)
        setTimeout(() => {
            drop.remove();
        }, 20500)
    }
    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const startRace = (animal) => {
        //console.log(animal);
        if (animal) {
            animal.id == game.winner.id ? setModalText({ win: true, winnerAnimal: game.winner.name[props.fastio.lang] }) : setModalText({ win: false, winnerAnimal: game.winner.name[props.fastio.lang] })
            setModal({ display: false })
            setTimeout(() => {
                setScene(game.scene.state.ready);
                Object.values(game.animals).forEach((animal, i) => {
                    useStateSetAnimals[i](game.animals[animal.id].state.ready)
                    setTimeout(() => {
                        useStateSetAnimals[i](game.animals[animal.id].state.run)
                        setTimeout(() => {
                            useStateSetAnimals[i](game.animals[animal.id].state.end)
                            //console.log(game.animals[animal.id].id)
                        }, (game.animals[animal.id].state.run.speed * 1000))
                        setTimeout(() => {
                            setModal({ display: true, type: 'result' });
                            setTimeout(() => {
                                setModal({ display: false });
                                useStateSetAnimals[i](game.animals[animal.id].state.reset)
                                setTimeout(() => {
                                    useStateSetAnimals[i](game.animals[animal.id].state.initial)
                                }, 3000)
                            }, 5000)
                        }, (game.scene.endTime * 1000 + 500))

                    }, 2000)
                });
                setTimeout(() => {
                    setScene(game.scene.state.run);
                    setTimeout(() => {
                        setFinishLine(true);
                    }, (game.scene.state.run.speed * 1000 - 1000))
                    setTimeout(() => {
                        setScene(game.scene.state.end);
                        setTimeout(() => {
                            setScene(game.scene.state.reset);
                            setFinishLine(false);
                            setTimeout(() => {
                                setScene(game.scene.state.initial);
                            }, 3000);
                        }, 5000);
                    }, (game.scene.endTime * 1000 + 500));
                }, 2000);
            }, 1000);

        } else {
            setModal({ display: true, type: 'pick' });
            var times = [25, 31, 35, 38, 40];
            game.scene.state.run.speed = times[0] - 2;
            game.scene.endTime = times[times.length - 1];
            shuffle(times);
            Object.values(game.animals).forEach((animal, i) => {
                animal.state.run.speed = times[i]
                if (times[i] == Math.min(...times)) {
                    game.winner = animal;
                    //console.log(animal.id)
                }
            });
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader();
        const startRain = setInterval(rainMachine, 5000);
        return () => {
            // This function will be called just before the component unmounts.
            // Remove listeners, subscriptions, etc.    
            clearInterval(startRain);
        };
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);
    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://fastio.pt/kids" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>Fastio Kids - Água do Fastio</title>
            </Helmet>
            <section className="kids">
                <div className={'modal ' + (modal.display && 'show')}>
                    <div className={'winner-pick ' + (modal.type === 'pick' && 'show')}>
                        <h3>{props.fastio.langKeys.kids.text[props.fastio.lang].str1}</h3>
                        <ul>
                            {Object.values(game.animals).map((animal, i) => (
                                <li key={i}>
                                    <img alt="" src={setImage('animals/' + animal.id + '.gif')} onClick={() => startRace(animal)} />
                                </li>
                            ))}
                        </ul>
                        <p>{props.fastio.langKeys.kids.text[props.fastio.lang].str2}</p>
                    </div>
                    <div className={'race-result ' + (modal.type === 'result' && 'show')}>
                        <svg version="1.1" id="_x31_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 234.95 114.24" className="flags" style={{ "enableBackground": "new 0 0 234.95 114.24" }} xmlSpace="preserve">

                            <g>
                                <g>
                                    <path style={{ "fill": "#00AEEF" }} d="M234.39,53.35C234.39,53.35,234.39,53.35,234.39,53.35c-34.78,6.49-44.97-52.83-80.56-44.64
			c-0.19,0.04-0.38,0.08-0.58,0.12c-5.35,19.45-15.48,38.55-29.77,56.1c0.23-0.04,0.46-0.07,0.68-0.11
			c40.81-5.12,40.37,47.96,80.35,44.59c0,0,0,0,0.01,0c0.22-0.02,0.44-0.05,0.66-0.07c14.28-17.53,24.42-36.63,29.78-56.1
			C234.76,53.29,234.57,53.32,234.39,53.35z M223.46,53.76c-1.23,3.9-2.62,7.75-4.17,11.54c-3.44-0.65-6.6-1.91-9.56-3.62
			c1.6-3.83,3.04-7.74,4.33-11.71C217,51.74,220.11,53.06,223.46,53.76z M196.18,66.98c1.89-3.73,3.64-7.55,5.24-11.46
			c2.59,2.4,5.29,4.52,8.19,6.21c-1.61,3.86-3.37,7.65-5.29,11.36C201.42,71.43,198.74,69.34,196.18,66.98z M181.52,50.68
			c1.79-3.81,3.44-7.71,4.95-11.7c2.43,2.79,4.82,5.66,7.24,8.43c-1.56,3.95-3.27,7.82-5.11,11.59
			C186.22,56.27,183.89,53.44,181.52,50.68z M165.86,36.18c1.7-3.83,3.26-7.73,4.65-11.69c2.89,1.72,5.61,3.88,8.2,6.31
			c-1.44,4-3.04,7.92-4.78,11.75C171.39,40.12,168.73,37.94,165.86,36.18z M160.28,47.29c-2.91-1.79-6.04-3.16-9.49-3.94
			c1.99-3.6,3.83-7.27,5.51-10.99c3.41,0.75,6.53,2.1,9.45,3.88C164.07,40,162.24,43.68,160.28,47.29z M205.73,43.49
			c-1.3,4.04-2.74,8-4.33,11.88c-2.62-2.44-5.13-5.16-7.59-7.98c1.55-3.92,2.96-7.94,4.22-12.03
			C200.54,38.22,203.08,40.99,205.73,43.49z M190.48,26.69c-1.2,4.12-2.54,8.16-4.04,12.12c-2.47-2.83-4.99-5.58-7.63-8.05
			c1.43-3.97,2.71-8.01,3.84-12.11C185.36,21.09,187.94,23.84,190.48,26.69z M174.13,12.4c-1.05,4.03-2.28,8.02-3.66,11.95
			c-2.95-1.74-6.08-3.03-9.47-3.71c1.36-3.85,2.56-7.75,3.58-11.66C167.98,9.54,171.14,10.73,174.13,12.4z M149.95,20.67
			c3.96-0.67,7.57-0.61,10.94,0.05c-1.38,3.89-2.93,7.73-4.64,11.52c-3.39-0.74-7.06-0.88-11.12-0.29
			C146.91,28.23,148.52,24.46,149.95,20.67z M139.28,43.02c4.19-0.56,7.94-0.37,11.37,0.39c-2.02,3.66-4.2,7.25-6.53,10.76
			c-3.5-0.76-7.34-0.94-11.69-0.37C134.88,50.26,137.16,46.66,139.28,43.02z M136.76,64.52c2.61-3.34,5.08-6.75,7.39-10.24
			c3.52,0.78,6.68,2.13,9.59,3.9c-2.27,3.45-4.68,6.81-7.23,10.08C143.57,66.55,140.37,65.25,136.76,64.52z M161.69,82.02
			c-2.27-2.66-4.6-5.24-7.09-7.59c2.56-3.22,4.99-6.55,7.29-9.99c-2.49-2.39-5.13-4.55-8.02-6.31c2.29-3.48,4.43-7.06,6.43-10.72
			c2.88,1.78,5.54,3.97,8.06,6.4c2.01-3.61,3.87-7.32,5.6-11.12c2.59,2.47,5.04,5.2,7.46,8c-1.78,3.77-3.7,7.45-5.74,11.03
			c-2.36-2.76-4.77-5.46-7.31-7.91c-2.02,3.64-4.18,7.19-6.48,10.62c2.52,2.41,4.88,5.06,7.19,7.77
			C166.74,75.6,164.28,78.87,161.69,82.02z M175.4,97.73c-2.45-2.38-4.74-4.98-6.99-7.65c2.63-3.09,5.15-6.31,7.53-9.65
			c-2.3-2.7-4.56-5.49-6.87-8.21c2.33-3.38,4.53-6.88,6.6-10.49c2.36,2.76,4.67,5.6,7.03,8.33c2.1-3.53,4.07-7.17,5.91-10.91
			c2.41,2.78,4.87,5.45,7.46,7.85c-1.88,3.7-3.9,7.31-6.05,10.81c-2.55-2.38-4.96-5.02-7.32-7.76c0,0,0,0,0,0
			c-2.12,3.56-4.38,7.02-6.76,10.36c0,0,0,0,0,0c2.3,2.7,4.65,5.32,7.16,7.69C180.66,91.44,178.09,94.65,175.4,97.73z M192.92,108.2
			c-3.56-0.88-6.71-2.31-9.59-4.12c2.75-3.12,5.37-6.36,7.86-9.71c-2.92-1.74-5.58-3.88-8.08-6.25c2.44-3.32,4.75-6.76,6.92-10.29
			c2.55,2.38,5.25,4.5,8.2,6.2c2.19-3.51,4.24-7.12,6.13-10.8c2.96,1.69,6.16,2.93,9.67,3.56c-1.88,3.68-3.91,7.31-6.1,10.86
			c-3.55-0.66-6.75-1.94-9.7-3.63c0,0,0,0,0,0c-2.21,3.54-4.56,7-7.04,10.35c0,0,0,0,0,0c2.92,1.73,6.11,3.07,9.68,3.82
			C198.35,101.63,195.7,104.97,192.92,108.2z M212.79,98.46c-4.42,0.64-8.37,0.48-11.94-0.26c2.51-3.43,4.86-6.95,7.07-10.54
			c3.55,0.66,7.45,0.72,11.78-0.02C217.57,91.29,215.26,94.91,212.79,98.46z M225.59,76.59c-4.16,0.76-7.95,0.75-11.44,0.14
			c1.89-3.71,3.62-7.48,5.18-11.31c3.41,0.63,7.1,0.66,11.09-0.07C229,69.12,227.38,72.87,225.59,76.59z"/>

                                    <rect x="61.65" y="54.93" transform="matrix(0.4688 -0.8833 0.8833 0.4688 16.0278 140.8885)" style={{ "fill": "#00AEEF" }} width="127.01" height="4.37" />
                                </g>
                                <g>
                                    <path style={{ "fill": "#00AEEF" }} d="M0,53.25c5.36,19.47,15.5,38.58,29.77,56.1c0.22,0.03,0.44,0.05,0.66,0.07c0,0,0,0,0,0
			c39.98,3.37,39.54-49.71,80.35-44.59c0.23,0.03,0.45,0.07,0.68,0.11C97.18,47.39,87.04,28.29,81.69,8.84
			c-0.19-0.04-0.38-0.08-0.58-0.12C45.54,0.52,35.34,59.85,0.56,53.35c0,0,0,0,0,0C0.37,53.32,0.19,53.29,0,53.25z M20.88,49.97
			c1.29,3.97,2.74,7.88,4.33,11.71c-2.96,1.71-6.12,2.97-9.56,3.62c-1.55-3.79-2.94-7.64-4.17-11.54
			C14.83,53.06,17.95,51.74,20.88,49.97z M30.61,73.1c-1.91-3.71-3.68-7.5-5.29-11.36c2.91-1.69,5.61-3.82,8.19-6.21
			c1.6,3.91,3.35,7.73,5.25,11.46C36.21,69.34,33.52,71.43,30.61,73.1z M46.35,59c-1.85-3.77-3.55-7.63-5.12-11.59
			c2.42-2.77,4.81-5.65,7.24-8.43c1.51,3.99,3.16,7.89,4.95,11.7C51.06,53.44,48.73,56.27,46.35,59z M61.01,42.55
			c-1.74-3.83-3.33-7.75-4.78-11.75c2.6-2.43,5.31-4.59,8.2-6.31c1.39,3.96,2.95,7.87,4.65,11.69
			C66.22,37.94,63.55,40.12,61.01,42.55z M69.2,36.23c2.92-1.78,6.04-3.13,9.45-3.88c1.68,3.72,3.52,7.39,5.51,10.99
			c-3.45,0.78-6.58,2.15-9.49,3.94C72.71,43.68,70.88,40,69.2,36.23z M36.92,35.35c1.26,4.09,2.67,8.1,4.22,12.03
			c-2.47,2.82-4.97,5.54-7.59,7.98c-1.59-3.88-3.03-7.84-4.33-11.88C31.87,40.99,34.41,38.22,36.92,35.35z M52.3,18.65
			c1.13,4.1,2.41,8.14,3.84,12.11c-2.64,2.47-5.16,5.22-7.63,8.05c-1.49-3.96-2.84-8.01-4.04-12.12C47,23.84,49.59,21.09,52.3,18.65
			z M70.36,8.98c1.02,3.91,2.22,7.81,3.58,11.66c-3.39,0.68-6.52,1.97-9.47,3.71c-1.38-3.93-2.6-7.92-3.66-11.95
			C63.81,10.73,66.97,9.54,70.36,8.98z M89.82,31.95c-4.06-0.59-7.73-0.45-11.12,0.29c-1.71-3.79-3.25-7.63-4.64-11.52
			C77.43,20.06,81.04,20,85,20.67C86.43,24.46,88.04,28.23,89.82,31.95z M102.52,53.81c-4.35-0.58-8.2-0.39-11.69,0.37
			c-2.33-3.52-4.51-7.1-6.53-10.76c3.43-0.77,7.18-0.95,11.37-0.39C97.79,46.66,100.07,50.26,102.52,53.81z M88.44,68.26
			c-2.55-3.27-4.96-6.63-7.23-10.08c2.91-1.77,6.07-3.12,9.59-3.9c2.31,3.49,4.77,6.9,7.39,10.24
			C94.58,65.25,91.38,66.55,88.44,68.26z M65.88,72.22c2.31-2.72,4.67-5.36,7.19-7.77c-2.29-3.44-4.45-6.98-6.48-10.62
			c-2.54,2.45-4.95,5.15-7.31,7.91c-2.05-3.58-3.97-7.25-5.75-11.03c2.41-2.8,4.87-5.53,7.45-8c1.73,3.8,3.59,7.51,5.6,11.12
			c2.52-2.43,5.18-4.62,8.06-6.4c2,3.66,4.15,7.24,6.43,10.72c-2.88,1.76-5.52,3.92-8.02,6.31c2.29,3.44,4.73,6.77,7.29,9.99
			c-2.5,2.35-4.82,4.93-7.09,7.59C70.67,78.87,68.21,75.6,65.88,72.22z M51.85,88.12c2.5-2.38,4.85-4.99,7.16-7.69c0,0,0,0,0,0
			c-2.38-3.34-4.64-6.79-6.76-10.36c0,0,0,0,0,0c-2.36,2.74-4.76,5.38-7.32,7.76c-2.15-3.51-4.17-7.12-6.05-10.81
			c2.6-2.4,5.05-5.08,7.47-7.85c1.83,3.73,3.8,7.37,5.91,10.91c2.36-2.74,4.67-5.57,7.03-8.33c2.06,3.6,4.27,7.1,6.6,10.49
			c-2.31,2.72-4.57,5.51-6.87,8.21c2.38,3.34,4.9,6.56,7.53,9.65c-2.25,2.67-4.54,5.26-6.99,7.65
			C56.86,94.65,54.29,91.44,51.85,88.12z M34.09,98.2c3.57-0.75,6.75-2.08,9.68-3.82c0,0,0,0,0,0c-2.48-3.35-4.84-6.8-7.04-10.35
			l0,0c-2.95,1.7-6.15,2.97-9.7,3.63c-2.19-3.56-4.22-7.18-6.1-10.86c3.51-0.63,6.7-1.88,9.67-3.56c1.9,3.68,3.95,7.28,6.14,10.8
			c2.95-1.7,5.65-3.82,8.2-6.2c2.17,3.54,4.48,6.97,6.92,10.29c-2.5,2.38-5.16,4.51-8.08,6.25c2.49,3.35,5.11,6.59,7.86,9.71
			c-2.88,1.8-6.04,3.24-9.59,4.12C39.25,104.97,36.6,101.63,34.09,98.2z M15.24,87.64c4.33,0.74,8.23,0.68,11.78,0.02
			c2.2,3.59,4.56,7.1,7.07,10.54c-3.56,0.75-7.51,0.9-11.94,0.26C19.69,94.91,17.38,91.29,15.24,87.64z M4.51,65.34
			c3.99,0.73,7.68,0.7,11.09,0.07c1.56,3.82,3.29,7.6,5.18,11.31c-3.48,0.62-7.28,0.63-11.43-0.14C7.57,72.87,5.95,69.12,4.51,65.34
			z"/>
                                    <rect x="107.61" y="-6.39" transform="matrix(0.8833 -0.4689 0.4689 0.8833 -13.9645 58.1473)" style={{ "fill": "#00AEEF" }} width="4.37" height="127.01" />
                                </g>
                            </g>
                        </svg>
                        <h3>{props.fastio.langKeys.kids.btn[props.fastio.lang].str3}</h3>
                        <p className="winner">{props.fastio.langKeys.kids.text[props.fastio.lang].str4}: {modalText.winnerAnimal}</p>
                        {modalText.win ? <p className="result">{props.fastio.langKeys.kids.text[props.fastio.lang].str6}</p> : <p className="result">{props.fastio.langKeys.kids.text[props.fastio.lang].str5}</p>}
                    </div>
                </div>
                <div className="sky">
                    <a className="anim" href="http://www.albumdefamilia.pt" rel="noopener" target="_blank">
                        <img alt="" src={setImage('albumdefamilia/logoalbum.png')} />
                    </a>
                </div>
                <div className="surface anim"></div>
                <div className="stage">
                    <div className={"finish-line " + (finishLine && "show")}></div>
                    <ul className={"clouds " + scene.class} style={{ 'transition': 'left ' + scene.speed + 's ease-in-out' }}>
                        <li className="anim">
                            {/* <img className="album2" alt="" src={setImage('albumdefamilia/criancas.png')} />
                            <img className="album1" alt="" src={setImage('albumdefamilia/casal.png')} /> */}
                            <img className="clouds1" alt="" src={setImage('clouds/nuvens.png')} />
                            <img className="clouds2" alt="" src={setImage('clouds/nuvens.png')} />
                            <img className="clouds3" alt="" src={setImage('clouds/nuvens.png')} />
                            <img className="clouds4" alt="" src={setImage('clouds/nuvens.png')} />
                            <img className="clouds5" alt="" src={setImage('clouds/nuvens.png')} />
                        </li>
                    </ul>
                    <ul className={"trees " + scene.class} style={{ 'transition': 'left ' + scene.speed + 's ease-in-out' }}>
                        <li className="anim">
                            <img className="tree1" alt="" src={setImage('trees/arbustotree.gif')} />
                            <img className="tree2" alt="" src={setImage('trees/arbustotree2.gif')} />
                            <img className="tree3" alt="" src={setImage('trees/arbustolobo.gif')} />
                            <img className="tree4" alt="" src={setImage('trees/arbustotree2.gif')} />
                            <img className="tree5" alt="" src={setImage('trees/arbustotree.gif')} />
                            <img className="tree6" alt="" src={setImage('trees/arbustotigre.gif')} />
                            <img className="tree7" alt="" src={setImage('trees/arbustotree.gif')} />
                            <img className="tree8" alt="" src={setImage('trees/arbustotree2.gif')} />
                        </li>
                    </ul>
                    <div className="animals anim">
                        {/* <img className={borboleta.class + " borboleta"} style={{ 'transition': 'left ' + (borboleta.speed) + 's ease-in' }} src={setImage('animals/borboleta.gif')} /> */}
                        {/* <img className={ovelha.class + " ovelha"} style={{ 'transition': 'left ' + (ovelha.speed) + 's ease-in' }} src={setImage('animals/ovelha' + ovelha.src.img + '.' + ovelha.src.ext)} /> */}
                        <img className={cabra.class + " cabra"} style={{ 'transition': 'left ' + (cabra.speed) + 's ease-in' }} src={setImage('animals/cabra' + cabra.src.img + '.' + cabra.src.ext)} />
                        {/* <img className={crocodilo.class + " crocodilo"} style={{ 'transition': 'left ' + (crocodilo.speed) + 's ease-in' }} src={setImage('animals/crocodilo' + crocodilo.src.img + '.' + crocodilo.src.ext)} /> */}
                        {/* <img className={mosca.class + " mosca"} style={{ 'transition': 'left ' + (mosca.speed) + 's ease-in' }} src={setImage('animals/mosca.gif')} /> */}
                        {/* <img className={veado.class + " veado"} style={{ 'transition': 'left ' + (veado.speed) + 's ease-in' }} src={setImage('animals/veado' + veado.src.img + '.' + veado.src.ext)} /> */}
                        <img className={cavalo.class + " cavalo"} style={{ 'transition': 'left ' + (cavalo.speed) + 's ease-in' }} src={setImage('animals/cavalo' + cavalo.src.img + '.' + cavalo.src.ext)} />
                        {/* <img className={zebra.class + " zebra"} style={{ 'transition': 'left ' + (zebra.speed) + 's ease-in' }} src={setImage('animals/zebra' + zebra.src.img + '.' + zebra.src.ext)} /> */}
                    </div>
                    <ul className={"bottles " + scene.class} style={{ 'transition': ('left ' + scene.speed + 's ease-in-out,width 1s ease-in-out') }}>
                        <li><img className="anim" src={setImage('bottle/garrafa1.png')} /></li>
                        <li><img className="anim" src={setImage('bottle/garrafa2.png')} /></li>
                        <li><img className="anim" src={setImage('bottle/garrafa3.png')} /></li>
                        <li><img className="anim" src={setImage('bottle/garrafa4.png')} /></li>
                    </ul>
                    <div className="animals anim">
                        {/* <img className={pato.class + " pato"} style={{ 'transition': 'left ' + (pato.speed) + 's ease-in' }} src={setImage('animals/pato' + pato.src.img + '.' + pato.src.ext)} /> */}
                        <img className={menina.class + " menina"} style={{ 'transition': 'left ' + (menina.speed) + 's ease-in' }} src={setImage('animals/menina' + menina.src.img + '.' + menina.src.ext)} />
                        <img className={esquilo.class + " esquilo"} style={{ 'transition': 'left ' + (esquilo.speed) + 's ease-in' }} src={setImage('animals/esquilo' + esquilo.src.img + '.' + esquilo.src.ext)} />
                        {/* <img className={passaroazul.class + " passaroazul"} style={{ 'transition': 'left ' + (passaroazul.speed) + 's ease-in' }} src={setImage('animals/passaroazul.gif')} /> */}
                        <img className={aguia.class + " aguia"} style={{ 'transition': 'left ' + (aguia.speed) + 's ease-in' }} src={setImage('animals/aguia.gif')} />
                        {/* <img className={caranguejo.class + " caranguejo"} style={{ 'transition': 'left ' + (caranguejo.speed) + 's ease-in' }} src={setImage('animals/caranguejo' + caranguejo.src.img + '.' + caranguejo.src.ext)} /> */}
                    </div>
                </div>
                <div className={"btn-play " + scene.btnClass} onClick={() => startRace()}>
                    {props.fastio.langKeys.kids.btn[props.fastio.lang].str1}
                </div>
            </section>
        </React.Fragment >
    )
}

export default Kids;