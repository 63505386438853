import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const Contact = (props) => {
    const locations = [
        { name: "Água do Fastio", position: [41.7375779, -8.2460826], size: 35, type: "fastio" }
        // ,{ name: "Portela do Homem", position: [41.809001, -8.131489], size: 35, type: "spot" }
    ];
    const loadAnim = () => {
        let i = 0;
        let elems = document.getElementsByClassName("anim");
        for (let key of elems) {
            key.style.transitionDelay = i < 9 ? ('0.' + i + 's') : (i / 10 + 's');
            setTimeout(() => {
                key.style.opacity = 1;
            }, 100)
            i++
        }
    }

    const ResizeMap = () => {
        const map = useMap();
        setTimeout(() => {
            map._onResize();
            return null;
        }, 1500);
    };

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/contact/' + path.split('.')[0] + "." + ext);
    }
    const GetIcon = (_iconSize, _iconType) => {
        return L.icon({
            iconUrl: setImage('pins/pin_' + _iconType + '.svg'),
            iconSize: [_iconSize]
        });
    }
    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader();
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://fastio.pt/contacto" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>Contacto - Água do Fastio</title>
            </Helmet>
            <section className="contact">
                {/* <div className="header-helper"></div> */}
                <div className="claim-canvas">
                    <div className="claim">
                        <div className="read-filter"></div>
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <h2 className="anim">{props.fastio.langKeys.contact.claim[props.fastio.lang].str1}</h2>
                                    <p className="anim">{props.fastio.langKeys.contact.claim[props.fastio.lang].str2[0]}<br />
                                        {props.fastio.langKeys.contact.claim[props.fastio.lang].str2[1]}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            className="scroll-icon" viewBox="0 0 25.25 64.24" style={{ "enableBackground": "new 0 0 25.25 64.24" }} xmlSpace="preserve">
                            <style type="text/css">
                            </style>
                            <g>
                                <polygon style={{ "fill": "#FFFFFF" }} points="12.63,48.51 21.71,48.51 17.17,56.38 12.63,64.24 8.09,56.38 3.55,48.51 	" />
                                <g>
                                    <path style={{ "fill": "#FFFFFF" }} d="M12.63,41.59C5.66,41.59,0,35.92,0,28.96V12.63C0,5.66,5.66,0,12.63,0c6.96,0,12.63,5.66,12.63,12.63v16.33
                                    C25.25,35.92,19.59,41.59,12.63,41.59z M12.63,1C6.22,1,1,6.22,1,12.63v16.33c0,6.41,5.22,11.63,11.63,11.63
			s11.63-5.22,11.63-11.63V12.63C24.25,6.22,19.04,1,12.63,1z"/>
                                </g>
                                <path style={{ "fill": "#FFFFFF" }} d="M12.63,9.19c-1.13,0-2.05,0.92-2.05,2.05v2.77c0,1.13,0.92,2.05,2.05,2.05c1.13,0,2.05-0.92,2.05-2.05v-2.77
		C14.68,10.11,13.76,9.19,12.63,9.19z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="location">
                    <div className="container">
                        <div className="content">
                            <div className="map">
                                <MapContainer id="map-canvas" center={[41.7375779, -8.2460826]} zoom={11} scrollWheelZoom={false} dragging={false}>
                                    <ResizeMap />
                                    <TileLayer
                                        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                                    />
                                    {locations.map((location, i) => (
                                        <Marker
                                            key={i}
                                            position={location.position}
                                            icon={GetIcon(location.size, location.type)}
                                            eventHandlers={{
                                                click: () => {
                                                    console.log('marker clicked')
                                                },
                                            }}>
                                            <Popup>
                                                {location.name}
                                            </Popup>
                                        </Marker>
                                    ))}
                                </MapContainer>
                            </div>
                            <div className="text">
                                <h2>{props.fastio.langKeys.contact.location[props.fastio.lang].str1} </h2>
                                <p>{props.fastio.langKeys.contact.location[props.fastio.lang].str2[0]}<br />
                                    {props.fastio.langKeys.contact.location[props.fastio.lang].str2[1]}</p>

                                <h2>{props.fastio.langKeys.contact.location[props.fastio.lang].str3}</h2>
                                <p>{props.fastio.langKeys.contact.location[props.fastio.lang].str4}</p>

                                <h2>{props.fastio.langKeys.contact.location[props.fastio.lang].str5}</h2>
                                <p><a href="tel:+351253359040">{props.fastio.langKeys.contact.location[props.fastio.lang].str6}</a></p>

                                <h2>{props.fastio.langKeys.contact.location[props.fastio.lang].str7}</h2>
                                <p><a>{props.fastio.langKeys.contact.location[props.fastio.lang].str8}</a></p>

                                <h2>{props.fastio.langKeys.contact.location[props.fastio.lang].str9}</h2>
                                <p><a href="mailto:fastio.net@eaa.pt">{props.fastio.langKeys.contact.location[props.fastio.lang].str10}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Contact;