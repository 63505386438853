import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const History = (props) => {

    let [selectedTime, setSelectedTime] = useState(150);

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/history/' + path.split('.')[0] + "." + ext);
    }

    const loadAnim = () => {
        let i = 0;
        let elems = document.getElementsByClassName("anim");
        for (let key of elems) {
            key.style.transitionDelay = i < 9 ? ('0.' + i + 's') : (i / 10 + 's');
            setTimeout(() => {
                setSelectedTime(0)
                key.style.opacity = 1;
            }, 1000)
            i++
        }
    }

    const travelTo = (time, method) => {
        let event = document.getElementsByClassName("event")[time];
        let movement = (time) * 57;
        setSelectedTime(movement);
        time != 0 ? event.scrollIntoView({ block: "center", behavior: "smooth", inline: "center" }) : window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const scrollCatch = () => {
        let i = 0;
        const events = document.getElementsByClassName("event");

        for (let event of events) {

            let eventProps = event.getBoundingClientRect();

            if (eventProps.top + ((eventProps.height / 3) * 2) < window.innerHeight) {
                let movement = (i) * 57;
                setSelectedTime(movement);
                Array.from(document.querySelectorAll('.year')).forEach((el) => el.classList.remove('active'));
                document.getElementsByClassName("year")[i].classList.add("active");
            }
            i++
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader()
        window.addEventListener('scroll', scrollCatch);
        return () => {
            // This function will be called just before the component unmounts.
            // Remove listeners, subscriptions, etc.    
            window.removeEventListener('scroll', scrollCatch);
        };
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://fastio.pt/history" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>História - Água do Fastio</title>
            </Helmet>
            <section className="history">
                {/* <div className="header-helper"></div> */}
                <div className="claim-canvas">
                    <div className="claim only-text event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_1920.png') + "')" }}>
                        <div className="read-filter"></div>
                        <div className="container">
                            <div className="content">

                                <div className="text">
                                    <h2 className="anim">{props.fastio.langKeys.history[props.fastio.lang].str1}
                                    </h2>
                                    <p className="anim">{props.fastio.langKeys.history[props.fastio.lang].str2}</p>
                                </div>
                            </div>
                        </div>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            className="scroll-icon" viewBox="0 0 25.25 64.24" style={{ "enableBackground": "new 0 0 25.25 64.24" }} xmlSpace="preserve">
                            <style type="text/css">
                            </style>
                            <g>
                                <polygon style={{ "fill": "#FFFFFF" }} points="12.63,48.51 21.71,48.51 17.17,56.38 12.63,64.24 8.09,56.38 3.55,48.51 	" />
                                <g>
                                    <path style={{ "fill": "#FFFFFF" }} d="M12.63,41.59C5.66,41.59,0,35.92,0,28.96V12.63C0,5.66,5.66,0,12.63,0c6.96,0,12.63,5.66,12.63,12.63v16.33
                                    C25.25,35.92,19.59,41.59,12.63,41.59z M12.63,1C6.22,1,1,6.22,1,12.63v16.33c0,6.41,5.22,11.63,11.63,11.63
			s11.63-5.22,11.63-11.63V12.63C24.25,6.22,19.04,1,12.63,1z"/>
                                </g>
                                <path style={{ "fill": "#FFFFFF" }} d="M12.63,9.19c-1.13,0-2.05,0.92-2.05,2.05v2.77c0,1.13,0.92,2.05,2.05,2.05c1.13,0,2.05-0.92,2.05-2.05v-2.77
		C14.68,10.11,13.76,9.19,12.63,9.19z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>Autorização para a exploração da Água do Fastio
                                </h2>
                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim only-text event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_1922.png') + "')" }}>
                    <div className="read-filter"></div>


                    <div className="container">
                        <div className="content">

                            <div className="text">
                                <h2>
                                    {props.fastio.langKeys.history[props.fastio.lang].str3}
                                </h2>
                                <p>
                                    {props.fastio.langKeys.history[props.fastio.lang].str4}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>
                                    Lançamento da Água do Fastio no mercado
                                </h2>
                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim only-text event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_1972.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>
                                    {props.fastio.langKeys.history[props.fastio.lang].str5}
                                </h2>
                                <p>
                                    {props.fastio.langKeys.history[props.fastio.lang].str6}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="claim event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_1979.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>
                                    {props.fastio.langKeys.history[props.fastio.lang].str7}
                                </h2>
                                <p>
                                    {props.fastio.langKeys.history[props.fastio.lang].str8}
                                </p>
                            </div>
                            <div className="image"><img src={setImage('events/fastioglass0_25.png')} /></div>

                        </div>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>
                                    Lançamento da Fastio Family 2,5L
                                </h2>
                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_2008.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>
                                    {props.fastio.langKeys.history[props.fastio.lang].str9}
                                </h2>
                                <p>
                                    {props.fastio.langKeys.history[props.fastio.lang].str10}
                                </p>
                            </div>
                            <div className="image"><img src={setImage('events/fastio2_5l.png')} /></div>
                        </div>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>Água do Fastio 6L é eleita Produto do Ano
                                </h2>
                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_2012.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.history[props.fastio.lang].str11}
                                </h2>
                                <p>{props.fastio.langKeys.history[props.fastio.lang].str12}
                                </p>
                            </div>
                            <div className="image"><img src={setImage('events/fastio6l.png')} /></div>
                        </div>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>Lançamento da Fastio 1L
                                </h2>
                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_2014.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.history[props.fastio.lang].str13}
                                </h2>
                                <p>{props.fastio.langKeys.history[props.fastio.lang].str14}
                                </p>

                            </div>
                            <div className="image"><img src={setImage('events/fastio1l.png')} /></div>
                        </div>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>Laçamento da Fastio Barbie
                                    e Fastio Hot Wheels
                                </h2>

                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_2017.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.history[props.fastio.lang].str15}
                                </h2>
                                <p>{props.fastio.langKeys.history[props.fastio.lang].str16}
                                </p>

                            </div>
                            <div className="image"><img src={setImage('events/fastiokids0_5l.png')} /></div>
                        </div>
                    </div>
                </div>
                {/* <div className="detail">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>Lançamento
                                    da Fastio GO 0,5L
                                </h2>

                            </div>
                            <div className="stamp"><img src={setOldImage('stamp/stamp.svg')} /></div>
                        </div>
                    </div>
                </div> */}
                <div className="claim event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_2018.png') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.history[props.fastio.lang].str17}</h2>
                                <p>{props.fastio.langKeys.history[props.fastio.lang].str18}
                                </p>

                            </div>
                            <div className="image"><img src={setImage('events/fastiogo0_5l.png')} /></div>
                        </div>
                    </div>
                </div>
                <div className="claim last event" style={{ "backgroundImage": "url('" + setImage('backgrounds/history_bg_2019.jpg') + "')" }}>
                    <div className="read-filter"></div>
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.history[props.fastio.lang].str19}</h2>
                                <p>{props.fastio.langKeys.history[props.fastio.lang].str20}</p>
                            </div>
                            <div className="image"><img src={setImage('events/fastio_newsletters.png')} /></div>
                        </div>
                    </div>
                </div>
                <div className="detail only-text event">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2>{props.fastio.langKeys.history[props.fastio.lang].str21}
                                </h2>
                                <p>{props.fastio.langKeys.history[props.fastio.lang].str22}

                                </p><p>
                                    {props.fastio.langKeys.history[props.fastio.lang].str23}
                                </p><p>
                                    {props.fastio.langKeys.history[props.fastio.lang].str24}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="time-travel">
                    <div className="container">
                        <div className="content">
                            <div className="curent-time anim"></div>
                            <ul className="anim" style={{ "top": "calc(50% - 9px - " + selectedTime + "px)" }}>
                                <li className={"year active"} onClick={() => travelTo(0)}>1920</li>
                                <li className={"year"} onClick={() => travelTo(1)}>1922</li>
                                <li className={"year"} onClick={() => travelTo(2)}>1972</li>
                                <li className={"year"} onClick={() => travelTo(3)}>1979</li>
                                <li className={"year"} onClick={() => travelTo(4)}>2008</li>
                                <li className={"year"} onClick={() => travelTo(5)}>2012</li>
                                <li className={"year"} onClick={() => travelTo(6)}>2014</li>
                                <li className={"year"} onClick={() => travelTo(7)}>2017</li>
                                <li className={"year"} onClick={() => travelTo(8)}>2018</li>
                                <li className={"year"} onClick={() => travelTo(9)}>2019</li>
                                <li className={"year"} onClick={() => travelTo(10)}>Futuro</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

export default History;