import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

let products = {
    pet: {
        regular: {
            sizes: [
                {
                    name: "6 lt",
                    img: "pet6lt.png"
                },
                {
                    name: "2,5 lt",
                    img: "pet2_5lt.png"
                },
                {
                    name: "1,5 lt",
                    img: "pet1_5lt.png"
                },
                {
                    name: "1 lt",
                    img: "pet1lt.png"
                },
                {
                    name: "0,50 lt",
                    img: "pet0_50lt.png"
                },
                {
                    name: "0,33 lt",
                    img: "pet0_33lt.png"
                }
            ]
        },
        fastioGo: {
            sizes: [
                {
                    name: "0,50 lt",
                    img: "petgo0_50lt.png"
                }
            ]
        }
        ,
        fastioKids: {
            sizes: [
                {
                    name: "0,50 lt",
                    img: "petkids0_50lt.png"
                }
            ]
        }
    },
    vidro: {
        regular: {
            sizes: [
                {
                    name: "1 lt",
                    img: "glass1lt.png"
                },
                {
                    name: "0,50 lt",
                    img: "glass0_50lt.png"
                },
                {
                    name: "0,33 lt",
                    img: "glass0_33lt.png"
                }
            ]
        }

    },
    pack: {
        regular: {
            sizes: [
                {
                    name: "6x 1,5 lt",
                    img: "pack6x1_5lt.png"
                },
                {
                    name: "4x 1,5 lt",
                    img: "pack4x1_5lt.png"
                },
                {
                    name: "6x 0,50 lt",
                    img: "pack6x0_50lt.png"
                },
                {
                    name: "6x 0,33 lt",
                    img: "pack6x0_25lt.png"
                }
            ]
        }
    }

}

const Home = (props) => {
    let [bottleRotation, setBottleRotation] = useState(0);
    let [featuresText1, setFeaturesText1] = useState(0);
    let [featuresText2, setFeaturesText2] = useState(0);
    let [featuresText3, setFeaturesText3] = useState(0);
    let [featuresText4, setFeaturesText4] = useState(0);

    let [productMaterial, setProductMaterial] = useState("pet");
    let [productGamma, setProductGamma] = useState("regular");
    let [productImg, setProductImg] = useState("pet6lt.png")

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/home/' + path.split('.')[0] + "." + ext);
    }

    const setVideo = (path) => {
        var ext = path.split('.')[1];
        return require('../../video/' + path.split('.')[0] + "." + ext);
    }

    const loadAnim = () => {
        // if (document.documentElement.scrollTop == 0) {
        //     setTimeout(() => {
        //         //setClaimDrop1(0)
        //         //setClaimTxt1(1)
        //     }, 1000)
        // }
    }

    const productRange = (material, gamma, size) => {
        setProductMaterial(material);
        setProductGamma(gamma);
        setProductImg(size)
    }

    const scrollCatch = () => {

        const customHomeFooter = document.getElementById("customHomeFooter");

        const video = document.getElementById("homeVideo");
        const features = document.getElementsByClassName("features")[0];
        const quote = document.getElementsByClassName("quote")[0];

        let videoProps = video.getBoundingClientRect();
        let featuresProps = features.getBoundingClientRect();
        let featuresScroll = parseInt((featuresProps.top) - featuresProps.height / 4);
        let quoteProps = quote.getBoundingClientRect();

        let bottleAnim = parseInt((featuresScroll * 45) / (featuresProps.height / 2));
        let bottleRotation = Math.abs(bottleAnim > 45 ? 45 : bottleAnim < 0 ? 0 : bottleAnim);

        let featuresText1;
        let featuresText2;
        let featuresText3;
        let featuresText4;

        videoProps.top + (videoProps.height / 2) < window.innerHeight && videoProps.bottom >= (videoProps.height / 2) ? video.play() : video.pause();
        featuresProps.top + (featuresProps.height / 4) < window.innerHeight ? featuresText1 = 1 : featuresText1 = 0;
        featuresProps.top + (featuresProps.height / 2) < window.innerHeight ? featuresText2 = 1 : featuresText2 = 0;
        featuresProps.top + ((featuresProps.height / 4) * 3) < window.innerHeight ? featuresText3 = 1 : featuresText3 = 0;
        featuresProps.top + (featuresProps.height - 100) < window.innerHeight ? featuresText4 = 1 : featuresText4 = 0;
        quoteProps.top + (quoteProps.height + 100) < window.innerHeight ? customHomeFooter.classList.add("up") : customHomeFooter.classList.remove("up")

        setBottleRotation(bottleRotation)
        setFeaturesText1(featuresText1)
        setFeaturesText2(featuresText2)
        setFeaturesText3(featuresText3)
        setFeaturesText4(featuresText4)
    }

    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader()
        window.addEventListener('scroll', scrollCatch);
        const customHomeFooter = document.getElementById("customHomeFooter");
        customHomeFooter.classList.add("active");
        return () => {
            // This function will be called just before the component unmounts.
            // Remove listeners, subscriptions, etc.
            window.removeEventListener('scroll', scrollCatch);
            customHomeFooter.classList.remove("active");
        };
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://bunkeragency.com/home" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>Água do Fastio</title>
            </Helmet>
            <section className="home">
                {/* <div className="header-helper"></div> */}
                <div className="highlight-canvas">
                    <div className="highlight">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            className="scroll-icon" viewBox="0 0 25.25 64.24" style={{ "enableBackground": "new 0 0 25.25 64.24" }} xmlSpace="preserve">
                            <style type="text/css">
                            </style>
                            <g>
                                <polygon style={{ "fill": "#FFFFFF" }} points="12.63,48.51 21.71,48.51 17.17,56.38 12.63,64.24 8.09,56.38 3.55,48.51 	" />
                                <g>
                                    <path style={{ "fill": "#FFFFFF" }} d="M12.63,41.59C5.66,41.59,0,35.92,0,28.96V12.63C0,5.66,5.66,0,12.63,0c6.96,0,12.63,5.66,12.63,12.63v16.33
                                    C25.25,35.92,19.59,41.59,12.63,41.59z M12.63,1C6.22,1,1,6.22,1,12.63v16.33c0,6.41,5.22,11.63,11.63,11.63
			s11.63-5.22,11.63-11.63V12.63C24.25,6.22,19.04,1,12.63,1z"/>
                                </g>
                                <path style={{ "fill": "#FFFFFF" }} d="M12.63,9.19c-1.13,0-2.05,0.92-2.05,2.05v2.77c0,1.13,0.92,2.05,2.05,2.05c1.13,0,2.05-0.92,2.05-2.05v-2.77
		C14.68,10.11,13.76,9.19,12.63,9.19z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="purpose">
                    <div className="container">
                        <div className="content">
                            <div className="logotipo">
                                <img src={setImage('purpose/logo.svg')} />
                            </div>
                            <div className="graphs">
                                <ul>
                                    <li>
                                        <img src={setImage('purpose/zen.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str1}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/nutricao.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str2}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/reforco.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str3}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/hidrata.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str4}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/melhorar.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str5}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/diferenca.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str6}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/desporto.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str7}</h3>
                                    </li>
                                    <li>
                                        <img src={setImage('purpose/devolve.svg')} />
                                        <h3>{props.fastio.langKeys.home.purpose[props.fastio.lang].str8}</h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="bottle"><img src={setImage('general/small_bottle.png')} /></div>
                            <div className="text">
                                <p>{props.fastio.langKeys.home.purpose[props.fastio.lang].str9}</p>
                                <p>{props.fastio.langKeys.home.purpose[props.fastio.lang].str10}</p>
                                <p>{props.fastio.langKeys.home.purpose[props.fastio.lang].str11}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="video">
                    <div className="container">
                        <div className="content">
                            <video id="homeVideo" width="100%" height="auto" loop muted>
                                <source src={setVideo('homevideo.mp4')} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className="features">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <h2 style={{ "opacity": featuresText1 }}>{props.fastio.langKeys.home.features[props.fastio.lang].str1}</h2>
                                <p style={{ "opacity": featuresText1 }}>{props.fastio.langKeys.home.features[props.fastio.lang].str2}</p>
                                <h2 style={{ "opacity": featuresText2 }}>{props.fastio.langKeys.home.features[props.fastio.lang].str3}</h2>
                                <p style={{ "opacity": featuresText2 }}>
                                    {props.fastio.langKeys.home.features[props.fastio.lang].str4}
                                </p>
                                <h2 style={{ "opacity": featuresText3 }}>{props.fastio.langKeys.home.features[props.fastio.lang].str5}</h2>
                                <p style={{ "opacity": featuresText3 }}>
                                    {props.fastio.langKeys.home.features[props.fastio.lang].str6}
                                </p>
                                <h2 style={{ "opacity": featuresText4 }}>{props.fastio.langKeys.home.features[props.fastio.lang].str7}</h2>
                                <p style={{ "opacity": featuresText4 }}>
                                    {props.fastio.langKeys.home.features[props.fastio.lang].str8}
                                </p>
                            </div>
                            <div className="bottle" style={{ "transform": 'rotate(' + bottleRotation + 'deg)' }}><img src={setImage('general/big_bottle.png')} /></div>
                        </div>
                    </div>
                </div>
                <div className="range">
                    <div className="container">
                        <div className="content">
                            <div className="m-header">
                                <h3>{props.fastio.langKeys.home.range[props.fastio.lang].str1}</h3>
                                <p>{props.fastio.langKeys.home.range[props.fastio.lang].str2}</p>
                            </div>
                            <div className="product">
                                <img src={setImage('range/' + productImg)} />
                            </div>
                            <div className="products">
                                <div className="d-header">
                                    <h3>{props.fastio.langKeys.home.range[props.fastio.lang].str1}</h3>
                                    <p>{props.fastio.langKeys.home.range[props.fastio.lang].str2}</p>
                                </div>
                                <ul className="material">
                                    {Object.getOwnPropertyNames(products).map((material, i) => (
                                        <li key={i} className={productMaterial == material ? "active" : ""} onClick={() => productRange(material, "regular", products[material]["regular"].sizes[0].img)}>
                                            <span>{props.fastio.langKeys.home.range[props.fastio.lang].str3[i]}</span>
                                        </li>
                                    ))}
                                </ul>
                                <ul className="gamma">
                                    {Object.getOwnPropertyNames(products[productMaterial]).map((gamma, i) => (
                                        <li key={i} className={productGamma == gamma ? "active" : ""} onClick={() => productRange(productMaterial, gamma, products[productMaterial][gamma].sizes[0].img)}>
                                            <span>{gamma}</span>
                                        </li>
                                    ))}
                                </ul>
                                <ul className="size">
                                    {products[productMaterial][productGamma].sizes.map((size, i) => (
                                        <li key={i} className={productImg == size.img ? "active" : ""} onClick={() => productRange(productMaterial, productGamma, size.img)}>
                                            <span>{size.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote">
                    <div className="container">
                        <div className="content">
                            <img className="background bk1" src={setImage('quote/bottlefooter1.png')} />
                            <img className="background bk2" src={setImage('quote/bottlefooter2.png')} />
                            <img className="background bk3" src={setImage('quote/bottlefooter3.png')} />
                            <p>{props.fastio.langKeys.home.quote[props.fastio.lang].str1}</p>
                        </div>
                    </div>
                </div>
                <img className="quote-highlight" src={setImage('quote/mountain.png')} />
            </section>
        </React.Fragment >
    )
}

export default Home;