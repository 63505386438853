import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
// import { useSwipeable } from 'react-swipeable';
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { isMobile } from 'react-device-detect';

const Explore = (props) => {

    const locations = [
        { name: "O Soajo", position: [41.87235373357942, -8.262264546501687], size: 35, type: "spot" },
        { name: "A Pedra Bela", position: [41.71618998473937, -8.153313742706416], size: 35, type: "spot" },
        { name: "Santuário da Peneda", position: [41.974471, -8.223302], size: 35, type: "spot" },
        { name: "As Cascatas", position: [41.71426014206799, -8.125931065987585], size: 35, type: "spot" },
        { name: "Fronteira Portela do Homem", position: [41.809001, -8.131489], size: 35, type: "spot" },
        { name: "Oficina de Engarrafamento Água do Fastio", position: [41.7375779, -8.2460826], size: 35, type: "fastio" }
    ];

    const loadAnim = () => {
        let i = 0;
        let elems = document.getElementsByClassName("anim");
        for (let key of elems) {
            key.style.transitionDelay = i < 9 ? ('0.' + i + 's') : (i / 10 + 's');
            setTimeout(() => {
                key.style.opacity = 1;
            }, 1000);
            i++
        }
        document.getElementById("penedaVideo").currentTime = 3;
        document.getElementById("soajoVideo").currentTime = 2;
        document.getElementById("pedraVideo").currentTime = 2;
        document.getElementById("cascataVideo").currentTime = 2;
    }

    const setImage = (path) => {
        var ext = path.split('.')[1];
        if (isWebpSupported() && isMobile) {
            // if (isWebpSupported()) {
            switch (ext) {
                case "jpg":
                case "gif":
                case "png":
                    ext = "webp";
                    break;
            }
        }
        return require('../../img/explore/' + path.split('.')[0] + "." + ext);
    }
    const setVideo = (path) => {
        var ext = path.split('.')[1];
        return require('../../video/' + path.split('.')[0] + "." + ext);
    }

    const ResizeMap = () => {
        const map = useMap();
        setTimeout(() => {
            map._onResize();
            return null;
        }, 1500);
    };

    const GetIcon = (_iconSize, _iconType) => {
        return L.icon({
            iconUrl: setImage('pins/pin_' + _iconType + '.svg'),
            iconSize: [_iconSize]
        });
    }

    const selectMapImg = (image) => {
        if (window.matchMedia('(min-width: 1120px)').matches) {
            let imgs = document.getElementsByClassName("dyn-map-img");
            for (let img of imgs) {
                img.classList.remove("active");
            }
            imgs[image].classList.add("active");
            setTimeout(() => {
                imgs[image].classList.remove("active");
            }, 3000);
        }
    };

    const scrollCatch = () => {

        const video = document.getElementById("penedaVideo");
        const video2 = document.getElementById("soajoVideo");
        const video3 = document.getElementById("pedraVideo");
        const video4 = document.getElementById("cascataVideo");
        let videoProps = video.getBoundingClientRect();
        let video2Props = video2.getBoundingClientRect();
        let video3Props = video3.getBoundingClientRect();
        let video4Props = video4.getBoundingClientRect();
        videoProps.top + (videoProps.height / 2) < window.innerHeight && videoProps.bottom >= (videoProps.height / 2) ? video.play() : video.pause();
        video2Props.top + (video2Props.height / 2) < window.innerHeight && video2Props.bottom >= (video2Props.height / 2) ? video2.play() : video2.pause();
        video3Props.top + (video3Props.height / 2) < window.innerHeight && video3Props.bottom >= (video3Props.height / 2) ? video3.play() : video3.pause();
        video4Props.top + (video4Props.height / 2) < window.innerHeight && video4Props.bottom >= (video4Props.height / 2) ? video4.play() : video4.pause();

    }
    // const handlers = useSwipeable({
    //     onSwiped: (eventData) => console.log(eventData)
    //use this on divs to scrol  {...handlers}

    // });
    const slideShow = (fn, slider, slides, slide) => {
        switch (fn) {
            case "build":
                let sliders = document.getElementsByClassName("slider");
                Array.from(sliders).forEach(slider => {
                    Array.from(slider.getElementsByClassName("slides")).forEach(slides => {
                        slides.style.width = slides.getElementsByClassName("slide").length * 100 + "%";
                        Array.from(slides.getElementsByClassName("slide")).forEach((slide, index) => {
                            slide.style.width = 100 / slides.getElementsByClassName("slide").length + "%";
                            if (slides.getElementsByClassName("slide").length < 2 && slider.getElementsByClassName("controls").length > 0) {
                                slider.getElementsByClassName("controls")[0].remove();
                                return
                            }
                            if (slider.getElementsByClassName("controls").length > 0) {
                                const bullet = document.createElement("li");
                                bullet.classList.add("bullet");
                                index == 0 && bullet.classList.add("active");
                                bullet.onclick = () => slideShow("move", slider, slides, index);
                                slider.getElementsByClassName("bullets")[0].appendChild(bullet);
                            }
                        });
                    });
                });
                break;
            case "move":
                slides.style.left = "-" + slide * 100 + "%"
                Array.from(slider.getElementsByClassName('bullet')).forEach((bullet, index) => {
                    bullet.classList.remove("active")
                });
                slider.getElementsByClassName('bullet')[slide].classList.add("active");
                break;
            case "destroy":
                Array.from(document.getElementsByClassName('bullet')).forEach(bullet => {
                    bullet.remove();
                });
                break;
            case "rebuild":
                slideShow("destroy");
                slideShow("build");
                break;
        }
    }
    useEffect(() => {
        window.scrollTo({ top: 0 })
        props.fastio.togglePreLoader()
        window.addEventListener('scroll', scrollCatch);
        slideShow("rebuild");
        return () => {
            // This function will be called just before the component unmounts.
            // Remove listeners, subscriptions, etc.    
            window.removeEventListener('scroll', scrollCatch);
        };
    }, []);

    useEffect(() => {
        !props.fastio.preLoader && loadAnim();
    }, [props]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href="https://fastio.pt/explore" />
                <meta name="description" content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês." />
                <title>Explore - Água do Fastio</title>
            </Helmet>
            <section className="explore">
                {/* <div className="header-helper"></div> */}
                <div className="claim-canvas">
                    <div className="claim">
                        <div className="read-filter"></div>
                        <div className="container">
                            <div className="content">
                                <div className="text" >
                                    <h1 className="anim">{props.fastio.langKeys.explore.claim[props.fastio.lang].str1}</h1>
                                    <p className="anim">{props.fastio.langKeys.explore.claim[props.fastio.lang].str2[0]}<br />
                                        {props.fastio.langKeys.explore.claim[props.fastio.lang].str2[1]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            className="scroll-icon" viewBox="0 0 25.25 64.24" style={{ "enableBackground": "new 0 0 25.25 64.24" }} xmlSpace="preserve">
                            <style type="text/css">
                            </style>
                            <g>
                                <polygon style={{ "fill": "#FFFFFF" }} points="12.63,48.51 21.71,48.51 17.17,56.38 12.63,64.24 8.09,56.38 3.55,48.51 	" />
                                <g>
                                    <path style={{ "fill": "#FFFFFF" }} d="M12.63,41.59C5.66,41.59,0,35.92,0,28.96V12.63C0,5.66,5.66,0,12.63,0c6.96,0,12.63,5.66,12.63,12.63v16.33
                                    C25.25,35.92,19.59,41.59,12.63,41.59z M12.63,1C6.22,1,1,6.22,1,12.63v16.33c0,6.41,5.22,11.63,11.63,11.63
			s11.63-5.22,11.63-11.63V12.63C24.25,6.22,19.04,1,12.63,1z"/>
                                </g>
                                <path style={{ "fill": "#FFFFFF" }} d="M12.63,9.19c-1.13,0-2.05,0.92-2.05,2.05v2.77c0,1.13,0.92,2.05,2.05,2.05c1.13,0,2.05-0.92,2.05-2.05v-2.77
		C14.68,10.11,13.76,9.19,12.63,9.19z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="location">
                    <div className="container">
                        <div className="content">
                            <div className="map">
                                <MapContainer id="map-canvas" center={[41.78368465452342, -8.193704485275724]} zoom={10} scrollWheelZoom={false} dragging={false}>
                                    <ResizeMap />
                                    <TileLayer
                                        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" />
                                    {locations.map((location, i) => (
                                        <Marker
                                            key={i}
                                            position={location.position}
                                            icon={GetIcon(location.size, location.type)}
                                            eventHandlers={{
                                                click: () => {
                                                    i <= 3 && selectMapImg(i);
                                                },
                                            }}>
                                            <Popup>
                                                {location.name}
                                            </Popup>
                                        </Marker>
                                    ))}
                                </MapContainer>
                            </div>
                            <div className="images">
                                <div className="dyn-map-img"></div>
                                <div className="dyn-map-img"></div>
                                <div className="dyn-map-img"></div>
                                <div className="dyn-map-img"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-description">
                    <div className="container">
                        <div className="content">
                            <div className="image only-dt">
                                <img src={setImage('sliders/slider_one.jpg')} />
                            </div>
                            <div className="video">
                                <video id="soajoVideo" width="100%" height="auto" loop muted>
                                    <source src={setVideo('soajovideo.mp4')} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="slider">
                                <ul className="slides">
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.soajo[props.fastio.lang].str1}</h2>
                                            <p>{props.fastio.langKeys.explore.soajo[props.fastio.lang].str2}</p>
                                            <p>
                                                41°52'20.3"N 8°15'43.9"W</p>
                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.soajo[props.fastio.lang].str1}</h2>
                                            <p>
                                                {props.fastio.langKeys.explore.soajo[props.fastio.lang].str3[0]}<br />
                                                {props.fastio.langKeys.explore.soajo[props.fastio.lang].str3[1]}
                                            </p><p>
                                                {props.fastio.langKeys.explore.soajo[props.fastio.lang].str4}
                                            </p>
                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.soajo[props.fastio.lang].str1}</h2>
                                            <p> {props.fastio.langKeys.explore.soajo[props.fastio.lang].str5}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="controls">
                                    <ul className="bullets">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-description">
                    <div className="container">
                        <div className="content">
                            <div className="image only-dt"><img src={setImage('sliders/slider_nine.jpg')} /></div>
                            <div className="video">
                                <video id="pedraVideo" width="100%" height="auto" loop muted>
                                    <source src={setVideo('pedravideo.mp4')} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="slider">
                                <ul className="slides">
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.pedra[props.fastio.lang].str1}</h2>
                                            <p>{props.fastio.langKeys.explore.pedra[props.fastio.lang].str2}
                                            </p>
                                            <p>
                                                41°47'00.4"N 8°09'56.7"W
                                            </p></div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.pedra[props.fastio.lang].str1}</h2>
                                            <p>
                                                {props.fastio.langKeys.explore.pedra[props.fastio.lang].str3[0]}<br />
                                                {props.fastio.langKeys.explore.pedra[props.fastio.lang].str3[1]}</p>
                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.pedra[props.fastio.lang].str1}</h2>
                                            <p>
                                                {props.fastio.langKeys.explore.pedra[props.fastio.lang].str4[0]}<br />
                                                {props.fastio.langKeys.explore.pedra[props.fastio.lang].str4[1]}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="controls">
                                    <ul className="bullets">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-description">
                    <div className="container">
                        <div className="content">
                            <div className="image only-dt"><img src={setImage('sliders/slider_five.jpg')} /></div>
                            <div className="video">
                                <video id="penedaVideo" width="100%" height="auto" loop muted>
                                    <source src={setVideo('penedavideo.mp4')} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="slider">
                                <ul className="slides">
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.peneda[props.fastio.lang].str1}</h2>
                                            <p>{props.fastio.langKeys.explore.peneda[props.fastio.lang].str2}
                                            </p>
                                            <p>
                                                {props.fastio.langKeys.explore.peneda[props.fastio.lang].str3}</p>

                                            <p>
                                                41°43'25.8"N 8°07'47.9"W
                                            </p>
                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.peneda[props.fastio.lang].str1}</h2>

                                            <p>
                                                {props.fastio.langKeys.explore.peneda[props.fastio.lang].str4[0]}<br />
                                                {props.fastio.langKeys.explore.peneda[props.fastio.lang].str4[1]}
                                            </p>
                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.peneda[props.fastio.lang].str1}</h2>

                                            <p>
                                                {props.fastio.langKeys.explore.peneda[props.fastio.lang].str5}</p>

                                        </div>
                                    </li>
                                </ul>
                                <div className="controls">
                                    <ul className="bullets">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-description">
                    <div className="container">
                        <div className="content">
                            <div className="image only-dt"><img src={setImage('sliders/slider_seven.jpg')} /></div>
                            <div className="video">
                                <video id="cascataVideo" width="100%" height="auto" loop muted>
                                    <source src={setVideo('cascatavideo.mp4')} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="slider">
                                <ul className="slides">
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.cascatas[props.fastio.lang].str1}</h2>
                                            <p>{props.fastio.langKeys.explore.cascatas[props.fastio.lang].str2}</p>
                                            <p>
                                                41°43'25.8"N 8°07'47.9"W
                                            </p>
                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.cascatas[props.fastio.lang].str3}</h2>
                                            <p>{props.fastio.langKeys.explore.cascatas[props.fastio.lang].str4}
                                            </p>
                                            <p>
                                                {props.fastio.langKeys.explore.cascatas[props.fastio.lang].str5}

                                            </p>

                                        </div>
                                    </li>
                                    <li className="slide">
                                        <div className="text">
                                            <h2>{props.fastio.langKeys.explore.cascatas[props.fastio.lang].str6}</h2>
                                            <p>{props.fastio.langKeys.explore.cascatas[props.fastio.lang].str7}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="controls">
                                    <ul className="bullets">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}
export default Explore;